// src/components/AppShowcase.tsx

import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick'; // Optional: Remove if not using carousel
import 'slick-carousel/slick/slick.css'; // Optional: Remove if not using carousel
import 'slick-carousel/slick/slick-theme.css'; // Optional: Remove if not using carousel

// Import all screenshot images
import screenshot1 from '../assets/images/screenshot1.png';
import screenshot2 from '../assets/images/screenshot2.png';
import screenshot3 from '../assets/images/screenshot3.png';
import screenshot4 from '../assets/images/screenshot4.png';
import screenshot5 from '../assets/images/screenshot5.png';

// Import additional screenshots as needed

// (Optional) Import device frame image
// import deviceFrame from '../assets/images/device-frame.png';

const ShowcaseContainer = styled.section`
  padding: 60px 20px;
  background: linear-gradient(135deg, #ffffff, #e0e0e0);
`;

const SliderWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  .slick-slide {
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .slick-dots li button:before {
    color: #333;
  }

  .slick-prev:before,
  .slick-next:before {
    color: #333;
  }
`;

const ScreenshotWrapper = styled.div`
  position: relative;
  perspective: 1000px;
`;

const DeviceFrame = styled.div`
  width: 320px;
  height: 640px;
  border: 16px solid #ccc;
  border-radius: 36px;
  padding: 20px;
  background: #fff;
  box-shadow: inset 0 0 0 1px #999;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 768px) {
    width: 240px;
    height: 480px;
  }
`;

const ScreenshotImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
  display: block;
`;

const Caption = styled.div`
  margin-top: 15px;
  text-align: center;
  font-size: 16px;
  color: #333;
  font-weight: 500;
`;

const AppShowcase: React.FC = () => {
  // Array of screenshots with captions
  const screenshots = [
    { src: screenshot1, caption: 'Swipe through Recipes from other chefs' },
    { src: screenshot3, caption: 'Add your favorite recipes to your cookbooks' },
    { src: screenshot5, caption: 'Earn Real Cash for your Recipes!' },
    { src: screenshot2, caption: 'Create your own Cookbooks' },
    { src: screenshot4, caption: 'Organize and Manage' },
    // Add additional screenshots with captions here
  ];

  // Slider settings (Optional: Remove if not using carousel)
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Adjust based on the number of screenshots
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  return (
    <ShowcaseContainer>
      <SliderWrapper>
        <Slider {...sliderSettings}>
          {screenshots.map((screenshot, index) => (
            <ScreenshotWrapper key={index}>
              {/* (Optional) Wrap with DeviceFrame for contextual presentation */}
              <DeviceFrame>
                <ScreenshotImage
                  src={screenshot.src}
                  alt={`Olleat Screenshot ${index + 1}: ${screenshot.caption}`}
                />
              </DeviceFrame>
              <Caption>{screenshot.caption}</Caption>
            </ScreenshotWrapper>
          ))}
        </Slider>
      </SliderWrapper>
    </ShowcaseContainer>
  );
};

export default AppShowcase;
