// src/pages/VirtualRestaurantTerms.tsx
import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

// Styled Components
const TermsContainer = styled.div`
  font-family: 'Open Sans', sans-serif;
  color: #343a40;
  padding: 4rem 2rem;
  background-color: #ffffff;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;

  a {
    color: #007bff;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const SectionHeader = styled.h2`
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #343a40;
`;

const SubSectionHeader = styled.h3`
  font-size: 1.25rem;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
  color: #343a40;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const FinalAcknowledgment = styled.p`
  margin-top: 2rem;
  text-align: center;
  font-style: italic;
`;

// Main Component
const VirtualRestaurantTerms: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Virtual Restaurant Terms of Service - Olleat</title>
        <meta
          name="description"
          content="Terms of Service for participating in the Olleat Virtual Restaurant and earning royalties."
        />
      </Helmet>
      <TermsContainer>
        <Title>Olleat Virtual Restaurant Terms and Conditions</Title>

        {/* Section 1: Definitions */}
        <SectionHeader>1. Definitions</SectionHeader>
        <p>
          For the purposes of these Terms, the following definitions apply:
        </p>
        <p>
          - <BoldText>"User Content"</BoldText>: All content you submit to the Program, including recipes, images, descriptions, and any other materials.
          <br />
          - <BoldText>"Operational Expenses"</BoldText>: All expenses necessary to maintain and operate Olleat's services, including but not limited to server costs (e.g., Heroku, AWS), domain registration, email services (e.g., SendGrid), engineering salaries, and other administrative costs.
          <br />
          - <BoldText>"Recipe Views"</BoldText>: The number of times unique users view your recipe within the swipe functionality of the Olleat app.
        </p>

        {/* Section 2: Introduction */}
        <SectionHeader>2. Introduction</SectionHeader>
        <p>
          Welcome to the Olleat Virtual Restaurant Program ("Program"), commencing on <BoldText>January 1, 2025</BoldText>. These Terms and Conditions ("Terms") govern your participation in the Program starting from the launch date. By enrolling and participating in the Program, you agree to comply with and be bound by these Terms effective from January 1, 2025. If you do not agree with any part of these Terms, you must not participate in the Program.
        </p>

        {/* Section 3: Eligibility */}
        <SectionHeader>3. Eligibility</SectionHeader>

        <SubSectionHeader>3.1. Age Requirement</SubSectionHeader>
        <p>
          You must be at least 16 years old to use the Olleat app. Participation in the Program is limited to individuals who are at least 18 years old. By enrolling in the Program, you represent and warrant that you meet the minimum age requirement.
        </p>

        <SubSectionHeader>3.2. Residency</SubSectionHeader>
        <p>
          The Program is open only to U.S. citizens and legal residents of the United States. You must have a valid U.S. Social Security Number (SSN) or Individual Taxpayer Identification Number (ITIN).
        </p>

        <SubSectionHeader>3.3. Compliance with Laws</SubSectionHeader>
        <p>
          You must comply with all applicable federal, state, and local laws and regulations.
        </p>

        {/* Section 4: Enrollment and Participation */}
        <SectionHeader>4. Enrollment and Participation</SectionHeader>

        <SubSectionHeader>4.1. Enrollment Process</SubSectionHeader>
        <p>To participate in the Program, you must:</p>
        <ul>
          <li>Complete the enrollment form within the Olleat app.</li>
          <li>Agree to these Terms and any other agreements or policies referenced herein.</li>
          <li>Set up a Stripe account through the Olleat app for payment processing.</li>
          <li>Submit your tax information, including a completed IRS Form W-9, through Stripe.</li>
        </ul>

        <SubSectionHeader>4.2. Consent to Data Sharing</SubSectionHeader>
        <p>
          By participating in the Program, you consent to Olleat sharing your personal information with third-party service providers, including Stripe, for the purposes of identity verification, payment processing, and compliance with legal obligations.
        </p>

        <SubSectionHeader>4.3. Compliance with Anti-Money Laundering Regulations</SubSectionHeader>
        <p>
          Olleat complies with all applicable anti-money laundering (AML) and counter-terrorism financing (CTF) laws and regulations. By participating in the Program, you agree to cooperate with any due diligence procedures required by Olleat or its service providers to comply with legal obligations.
        </p>

        <SubSectionHeader>4.4. Compliance with Stripe's Terms</SubSectionHeader>
        <p>
          By setting up a Stripe account, you agree to comply with Stripe's Connected Account Agreement, available at{' '}
          <a href="https://stripe.com/connect-account/legal" target="_blank" rel="noopener noreferrer">
            https://stripe.com/connect-account/legal
          </a>
          . It is your responsibility to review and adhere to Stripe's terms and policies.
        </p>

        <SubSectionHeader>4.5. Approval</SubSectionHeader>
        <p>
          Enrollment in the Program is subject to approval by Olleat. Olleat reserves the right to accept or reject any enrollment at its sole discretion. You will be notified of your approval status via in-app notification.
        </p>

        {/* Section 5: User Representations and Warranties */}
        <SectionHeader>5. User Representations and Warranties</SectionHeader>
        <p>
          By participating in the Program, you represent and warrant that:
        </p>
        <ul>
          <li>
            All content you submit, including recipes, images, and descriptions ("User Content"), is your original work and does not infringe upon any third-party intellectual property rights.
          </li>
          <li>
            You have the right and authority to grant the licenses and permissions set forth in these Terms.
          </li>
          <li>
            Your participation does not violate any applicable laws or regulations.
          </li>
          <li>
            You will not engage in any fraudulent or deceptive practices, including artificially inflating recipe views.
          </li>
        </ul>

        {/* Section 6: License Grant and Intellectual Property Rights */}
        <SectionHeader>6. License Grant and Intellectual Property Rights</SectionHeader>

        <SubSectionHeader>6.1. License to Olleat</SubSectionHeader>
        <p>
          You grant Olleat a non-exclusive, worldwide, royalty-free, sublicensable, and transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform your User Content in connection with the Program and Olleat's business operations. This license is effective until you terminate your participation in the Program or remove the User Content, except as necessary for Olleat to fulfill its obligations under these Terms.
        </p>

        <SubSectionHeader>6.2. Ownership</SubSectionHeader>
        <p>
          You retain all rights, title, and interest in and to your User Content. Olleat does not claim ownership of your User Content.
        </p>

        {/* Section 7: Earnings and Payment Terms */}
        <SectionHeader>7. Earnings and Payment Terms</SectionHeader>

        <SubSectionHeader>7.1. Earnings Calculation</SubSectionHeader>

        <SubSectionHeader>7.1.1. Allotted Pool</SubSectionHeader>
        <p>
          The Allotted Pool for payouts is calculated as 50% of the ad revenue remaining after deducting Operational Expenses.
        </p>

        <SubSectionHeader>7.1.2. Calculation of Your Share</SubSectionHeader>
        <p>
          Your share of the Allotted Pool is determined by the ratio of your Recipe Views to the total Recipe Views of all Program participants during the payout period.
        </p>

        <SubSectionHeader>7.1.3. Calculation Formula</SubSectionHeader>
        <p>Your Earnings = (Your Recipe Views ÷ Total Recipe Views) × Allotted Pool</p>

        <SubSectionHeader>7.1.4. Example</SubSectionHeader>
        <p>
          - <BoldText>Total Ad Revenue:</BoldText> $100,000
          <br />
          - <BoldText>Operational Expenses:</BoldText> $40,000
          <br />
          - <BoldText>Allotted Pool:</BoldText> 50% × ($100,000 - $40,000) = 50% × $60,000 = $30,000
          <br />
          - <BoldText>Your Recipe Views:</BoldText> 5,000
          <br />
          - <BoldText>Total Recipe Views:</BoldText> 500,000
        </p>
        <p>
          <BoldText>Your Earnings Calculation:</BoldText>
          <br />
          (5,000 ÷ 500,000) × $30,000 = 0.01 × $30,000 = $300
        </p>

        <SubSectionHeader>7.2. Minimum Payout Threshold</SubSectionHeader>
        <p>
          The minimum payout amount is $20. If your earnings do not reach the minimum threshold, the balance will roll over to the next month. <BoldText>Unclaimed Earnings:</BoldText> If your accumulated earnings do not reach the minimum payout threshold within one (1) year, Olleat reserves the right to cancel the unpaid balance, and you forfeit any claim to those earnings.
        </p>

        <SubSectionHeader>7.3. Payout Schedule</SubSectionHeader>
        <p>
          Payouts are processed monthly and made through Stripe to the bank account linked in your Stripe account. <BoldText>The first payout will occur in February 2025 for earnings accrued during January 2025.</BoldText>
        </p>

        <SubSectionHeader>7.4. Transfer Fees</SubSectionHeader>
        <p>
          You are responsible for all fees associated with transferring funds to your bank account, including but not limited to transaction fees and currency conversion fees. These fees will be deducted from your earnings before payout.
        </p>

        <SubSectionHeader>7.5. Currency</SubSectionHeader>
        <p>All payouts are made in U.S. Dollars (USD).</p>

        <SubSectionHeader>7.6. Payment Processing</SubSectionHeader>
        <p>
          Payouts are subject to Stripe's terms and conditions. Olleat is not responsible for delays or issues caused by Stripe.
        </p>

        <SubSectionHeader>7.7. No Guarantee of Earnings</SubSectionHeader>
        <p>
          <BoldText>7.7.1. No Guarantee:</BoldText> You acknowledge and agree that participation in the Program does not guarantee any earnings. Payouts are contingent upon the generation of ad revenue exceeding Operational Expenses during the payout period.
        </p>
        <p>
          <BoldText>7.7.2. Zero or Negative Allotted Pool:</BoldText> In the event that Operational Expenses meet or exceed ad revenue for a given period, resulting in a zero or negative Allotted Pool, no payouts will be made for that period. Olleat is not obligated to compensate participants from any other funds.
        </p>

        <SubSectionHeader>7.8. Earnings Variability</SubSectionHeader>
        <p>
          Earnings may vary month to month due to factors such as ad revenue fluctuations, changes in Operational Expenses, and the number of Recipe Views. By participating in the Program, you acknowledge and accept that earnings are variable and not guaranteed.
        </p>

        {/* Section 8: Taxes and Financial Responsibilities */}
        <SectionHeader>8. Taxes and Financial Responsibilities</SectionHeader>
        <p>
          You are solely responsible for reporting and paying all applicable taxes on your earnings. You agree to provide accurate tax information and complete any required tax forms, including IRS Form W-9, through Stripe. Olleat will provide necessary tax documents, such as Form 1099-MISC for U.S. residents earning $600 or more in a calendar year. Olleat may report your earnings to tax authorities as required by law.
        </p>

        {/* Section 9: Content Guidelines and Prohibited Activities */}
        <SectionHeader>9. Content Guidelines and Prohibited Activities</SectionHeader>

        <SubSectionHeader>9.1. Acceptable Content</SubSectionHeader>
        <p>
          All User Content must comply with Olleat's Community Guidelines and must not contain content that:
        </p>
        <ul>
          <li>Is obscene, pornographic, or sexually explicit.</li>
          <li>Promotes violence, hate speech, discrimination, or harassment.</li>
          <li>Includes personal or private information of any third party without consent.</li>
          <li>Contains malware, viruses, or any other harmful software.</li>
          <li>Is misleading, false, or promotes illegal activities.</li>
        </ul>

        <SubSectionHeader>9.2. Prohibited Activities</SubSectionHeader>
        <p>You must not engage in activities including, but not limited to:</p>
        <ul>
          <li>Fraudulent behavior, such as artificially inflating recipe views.</li>
          <li>Violating any laws or regulations.</li>
          <li>Harassing or abusing other users.</li>
        </ul>

        <SubSectionHeader>9.3. Enforcement</SubSectionHeader>
        <p>
          Violation of these guidelines may result in:
        </p>
        <ul>
          <li>Removal of content.</li>
          <li>Suspension or termination of your participation in the Program.</li>
          <li>Forfeiture of any unpaid earnings.</li>
        </ul>

        {/* Section 10: Termination and Suspension */}
        <SectionHeader>10. Termination and Suspension</SectionHeader>

        <SubSectionHeader>10.1. By Olleat</SubSectionHeader>
        <p>
          Olleat reserves the right to suspend or terminate your participation at any time, with or without notice, for:
        </p>
        <ul>
          <li>Violation of these Terms.</li>
          <li>Engaging in fraudulent or prohibited activities.</li>
          <li>Breach of any applicable laws or regulations.</li>
        </ul>

        <SubSectionHeader>10.2. By User</SubSectionHeader>
        <p>
          You may terminate your participation in the Program at any time by providing written notice to Olleat.
        </p>

        <SubSectionHeader>10.3. Effects of Termination</SubSectionHeader>
        <p>Upon termination:</p>
        <ul>
          <li>Your license to participate in the Program ends immediately.</li>
          <li>Any outstanding earnings meeting the minimum payout threshold will be paid during the next payout cycle.</li>
          <li>Olleat retains the rights to use your previously submitted User Content as per the license granted.</li>
          <li><BoldText>Unpaid Earnings:</BoldText> If your participation is terminated by either party and you have unpaid earnings that meet the minimum payout threshold, those earnings will be disbursed in the next payout cycle. Earnings below the minimum threshold will be forfeited.</li>
        </ul>

        {/* Section 11: Limitation of Liability */}
        <SectionHeader>11. Limitation of Liability</SectionHeader>
        <p>
          To the maximum extent permitted by law, Olleat and its affiliates shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including loss of profits, data, or other intangible losses arising from your participation in the Program. Olleat's total liability to you for any claims arising under these Terms shall not exceed the total amount of earnings paid to you in the twelve (12) months preceding the claim.
        </p>

        {/* Section 12: Indemnification */}
        <SectionHeader>12. Indemnification</SectionHeader>
        <p>
          You agree to indemnify, defend, and hold harmless Olleat, its affiliates, officers, directors, employees, and agents from any claims, liabilities, damages, losses, and expenses, including legal fees, arising out of or related to:
        </p>
        <ul>
          <li>Your User Content.</li>
          <li>Your violation of these Terms.</li>
          <li>Your participation in the Program.</li>
        </ul>

        {/* Section 13: Dispute Resolution */}
        <SectionHeader>13. Dispute Resolution</SectionHeader>

        <SubSectionHeader>13.1. Governing Law</SubSectionHeader>
        <p>
          These Terms are governed by and construed in accordance with the laws of the State of Delaware, without regard to its conflict of law principles. Notwithstanding the foregoing, you may have additional rights under the mandatory consumer protection laws of your state of residence. Nothing in these Terms is intended to limit your rights under those laws.
        </p>

        <SubSectionHeader>13.2. Arbitration</SubSectionHeader>
        <p>
          Any disputes arising out of or relating to these Terms or the Program shall be resolved through binding arbitration administered by the American Arbitration Association under its Commercial Arbitration Rules. The arbitration shall take place in the county where you reside or another mutually agreed location. Proceedings will be conducted in English.
        </p>

        <SubSectionHeader>13.3. Opt-Out Provision</SubSectionHeader>
        <p>
          You have the right to opt-out of this arbitration agreement. To do so, you must notify us in writing within 30 days of accepting these Terms. Send your opt-out notice to:
        </p>
        <p>
          Olleat Legal Department
          <br />
          3835 Forest Inn Rd.
          <br />
          Palmerton, PA, 18071
        </p>
        <p>
          Your notice must include your name, address, and a clear statement that you do not wish to resolve disputes with Olleat through arbitration.
        </p>

        {/* Section 14: Modification of Terms */}
        <SectionHeader>14. Modification of Terms</SectionHeader>
        <p>
          Olleat reserves the right to modify these Terms at any time. We will provide you with notice of significant changes at least 30 days before they become effective, either through in-app notifications or via email. If you do not agree to the modified Terms, you must terminate your participation in the Program before the changes take effect. Continued participation after the effective date constitutes your acceptance of the new Terms.
        </p>

        <SubSectionHeader>14.1. Changes to the Program</SubSectionHeader>
        <p>
          Olleat reserves the right to modify, suspend, or discontinue the Program, or any part thereof, at any time with or without notice. Olleat shall not be liable to you or any third party for any such modification, suspension, or discontinuation.
        </p>

        {/* Section 15: Privacy and Data Protection */}
        <SectionHeader>15. Privacy and Data Protection</SectionHeader>

        <SubSectionHeader>15.1. Compliance with Privacy Laws</SubSectionHeader>
        <p>
          Your personal information is collected and processed in accordance with our Privacy Policy and applicable privacy laws, including the California Consumer Privacy Act (CCPA), Virginia Consumer Data Protection Act (VCDPA), Colorado Privacy Act (CPA), and other state privacy regulations.
        </p>

        <SubSectionHeader>15.2. Information Collected</SubSectionHeader>
        <p>
          We collect information including, but not limited to, your name, date of birth, bank details, tax identification, age, and IP address.
        </p>

        <SubSectionHeader>15.3. Use of Personal Information</SubSectionHeader>
        <p>
          This information is used for Program administration, payment processing, tax reporting, compliance with legal obligations, and improving our services.
        </p>

        <SubSectionHeader>15.4. User Rights</SubSectionHeader>
        <p>
          Depending on your state of residence, you may have certain rights regarding your personal information, such as:
        </p>
        <ul>
          <li>
            <BoldText>Right to Know:</BoldText> You can request details about the personal information we collect about you.
          </li>
          <li>
            <BoldText>Right to Delete:</BoldText> You can request the deletion of your personal information.
          </li>
          <li>
            <BoldText>Right to Opt-Out:</BoldText> You can opt-out of the sale of your personal information.
          </li>
        </ul>
        <p>
          To exercise these rights, please contact us at <a href="mailto:customer_support@familyforgeapps.com">customer_support@familyforgeapps.com</a>.
        </p>

        <SubSectionHeader>15.5. Data Protection</SubSectionHeader>
        <p>
          We employ industry-standard security measures to protect your personal information.
        </p>

        <SubSectionHeader>15.6. Consent</SubSectionHeader>
        <p>
          By participating in the Program, you consent to the collection, use, and sharing of your personal information as described in these Terms and our Privacy Policy.
        </p>

        {/* Section 16: Relationship of the Parties */}
        <SectionHeader>16. Relationship of the Parties</SectionHeader>
        <p>
          You and Olleat are independent contractors. Nothing in these Terms creates any partnership, joint venture, employment, or agency relationship. You are not authorized to make any representations or commitments on behalf of Olleat.
        </p>

        {/* Section 17: Assignment */}
        <SectionHeader>17. Assignment</SectionHeader>
        <p>
          You may not assign or transfer your rights or obligations under these Terms without Olleat's prior written consent. Olleat may assign or transfer its rights and obligations without restriction.
        </p>

        {/* Section 18: Severability */}
        <SectionHeader>18. Severability</SectionHeader>
        <p>
          If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.
        </p>

        {/* Section 19: Entire Agreement */}
        <SectionHeader>19. Entire Agreement</SectionHeader>
        <p>
          These Terms constitute the entire agreement between you and Olleat regarding the Program and supersede any prior agreements or understandings.
        </p>

        {/* Section 20: Force Majeure */}
        <SectionHeader>20. Force Majeure</SectionHeader>
        <p>
          Olleat shall not be liable for any failure to perform its obligations where such failure results from any cause beyond its reasonable control, including acts of God, natural disasters, war, terrorism, civil unrest, technical failures, or government actions.
        </p>

        {/* Section 21: No Waiver */}
        <SectionHeader>21. No Waiver</SectionHeader>
        <p>
          Failure by Olleat to enforce any right or provision of these Terms shall not constitute a waiver of future enforcement of that right or provision.
        </p>

        {/* Section 22: Communications */}
        <SectionHeader>22. Communications</SectionHeader>
        <p>
          All communications, including notices or other information concerning these Terms, will be provided via in-app notifications. For support or inquiries, you may contact us at <a href="mailto:customer_support@familyforgeapps.com">customer_support@familyforgeapps.com</a>.
        </p>

        <SubSectionHeader>22.1. Electronic Communications</SubSectionHeader>
        <p>
          By participating in the Program, you consent to receive electronic communications from Olleat, including but not limited to notifications, agreements, disclosures, and other information. These communications may be via email or in-app notifications.
        </p>

        {/* Section 23: No Third-Party Beneficiaries */}
        <SectionHeader>23. No Third-Party Beneficiaries</SectionHeader>
        <p>
          These Terms are intended solely for the benefit of you and Olleat. Nothing in these Terms, express or implied, is intended to or shall confer upon any third party any rights, benefits, or remedies of any nature whatsoever.
        </p>

        {/* Section 24: Survival */}
        <SectionHeader>24. Survival</SectionHeader>
        <p>
          The following sections shall survive termination or expiration of these Terms:
        </p>
        <ul>
          <li>Section 6: License Grant and Intellectual Property Rights</li>
          <li>Section 8: Taxes and Financial Responsibilities</li>
          <li>Section 11: Limitation of Liability</li>
          <li>Section 12: Indemnification</li>
          <li>Section 13: Dispute Resolution</li>
          <li>Section 15: Privacy and Data Protection</li>
          <li>Section 16: Relationship of the Parties</li>
          <li>Section 18: Severability</li>
          <li>Section 19: Entire Agreement</li>
          <li>Section 20: Force Majeure</li>
          <li>Section 21: No Waiver</li>
          <li>Section 23: No Third-Party Beneficiaries</li>
          <li>Section 24: Survival</li>
        </ul>

        {/* Section 25: Accessibility */}
        <SectionHeader>25. Accessibility</SectionHeader>
        <p>
          Olleat is committed to ensuring that our services, including these Terms, are accessible to users with disabilities. We strive to comply with the Web Content Accessibility Guidelines (WCAG) 2.1 <BoldText>at the AA level</BoldText>. If you have difficulty accessing any part of the Program or these Terms, please contact us at <a href="mailto:customer_support@familyforgeapps.com">customer_support@familyforgeapps.com</a> for assistance.
        </p>

        {/* Final Acknowledgment */}
        <FinalAcknowledgment>
          By clicking "I Agree" and participating in the Virtual Restaurant Program, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.
        </FinalAcknowledgment>
      </TermsContainer>
    </>
  );
};

export default VirtualRestaurantTerms;
