// src/components/Header.tsx

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaBars, FaTimes } from 'react-icons/fa';

// Styled Components

const HeaderContainer = styled.header`
  position: sticky;
  top: 0;
  width: 100%;
  background: linear-gradient(135deg, #ffffff, #f0f0f0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const Nav = styled.nav`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0.75rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* Responsive adjustments */
  @media (max-width: 768px) {
    padding: 0.75rem 1rem;
  }
`;

const Logo = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: #343a40;
  text-decoration: none;
  transition: color 0.3s ease, transform 0.3s ease;

  &:hover {
    color: #ff7f50; /* Coral color on hover */
    transform: scale(1.05);
  }

  /* Optional: Add a slight shadow or underline */
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #ff7f50;
    transition: width 0.3s;
    position: absolute;
    bottom: -4px;
    left: 0;
  }

  &:hover::after {
    width: 100%;
  }
`;

const NavLinks = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;

  /* Desktop view */
  @media (min-width: 769px) {
    a {
      margin: 0 1rem;
      text-decoration: none;
      color: #343a40;
      font-weight: 500;
      position: relative;
      transition: color 0.3s ease;

      &:hover {
        color: #ff7f50; /* Coral color on hover */
      }

      &::after {
        content: '';
        display: block;
        width: 0;
        height: 2px;
        background: #ff7f50;
        transition: width 0.3s;
        position: absolute;
        bottom: -4px;
        left: 0;
      }

      &:hover::after {
        width: 100%;
      }
    }
  }

  /* Mobile view */
  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    right: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
    height: 100vh;
    width: 250px;
    background: #ffffff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: right 0.3s ease-in-out;
    box-shadow: ${({ isOpen }) => (isOpen ? '-2px 0 5px rgba(0,0,0,0.3)' : 'none')};

    a {
      margin: 1.5rem 0;
      font-size: 1.2rem;
      color: #343a40;

      &:hover {
        color: #ff7f50;
      }
    }
  }
`;

const Hamburger = styled.div`
  display: none;
  font-size: 1.5rem;
  color: #343a40;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #ff7f50; /* Coral color on hover */
  }

  @media (max-width: 768px) {
    display: block;
  }
`;

const CloseIcon = styled(FaTimes)`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  font-size: 1.5rem;
  color: #343a40;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #ff7f50; /* Coral color on hover */
  }
`;

// Functional Component

const Header: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  // Toggle the mobile menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Close the mobile menu when a link is clicked
  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <HeaderContainer>
      <Nav>
        {/* Company Title */}
        <Logo to="/" onClick={closeMenu}>
          Family Forge Apps
        </Logo>

        {/* Navigation Links */}
        <NavLinks isOpen={isOpen}>
          {/* Close icon for mobile menu */}
          {isOpen && <CloseIcon onClick={toggleMenu} />}

          {/* Navigation Links */}
          <Link to="/" onClick={closeMenu}>Home</Link>
          <Link to="/about-us" onClick={closeMenu}>About Us</Link>
          <Link to="/about-olleat" onClick={closeMenu}>About Olleat</Link>
          <Link to="/contact-us" onClick={closeMenu}>Contact Us</Link>
        </NavLinks>

        {/* Hamburger Icon */}
        <Hamburger onClick={toggleMenu}>
          <FaBars />
        </Hamburger>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;
