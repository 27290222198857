// src/pages/PrivacyPolicy.tsx

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaGlobe, FaMobileAlt } from 'react-icons/fa';

const PolicyContainer = styled.div`
  padding: 4rem 2rem;
  background-color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  color: #343a40;
`;

const PolicyWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const PolicyTitle = styled.h1`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 3rem;
`;

const PolicyGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const PolicyCard = styled.div`
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const PolicyIcon = styled.div`
  font-size: 2rem;
  color: #ff7f50;
  margin-bottom: 1rem;
`;

const PolicyHeading = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const PolicyDescription = styled.p`
  font-size: 1rem;
  color: #6c757d;
  margin-bottom: 1.5rem;
`;

const PolicyButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ff7f50;
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 50px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #ff6347;
    transform: translateY(-3px);
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const PrivacyPolicy: React.FC = () => (
  <PolicyContainer>
    <PolicyWrapper>
      <PolicyTitle>Privacy Policy</PolicyTitle>
      <PolicyGrid>
        {/* Family Forge Apps Website Privacy Policy */}
        <PolicyCard>
          <PolicyIcon>
            <FaGlobe />
          </PolicyIcon>
          <PolicyHeading>Family Forge Apps Website</PolicyHeading>
          <PolicyDescription>
            Understand how we handle your data on our website.
          </PolicyDescription>
          <PolicyButton to="/privacy-policy/website">
            View Policy
          </PolicyButton>
        </PolicyCard>

        {/* Olleat App Privacy Policy */}
        <PolicyCard>
          <PolicyIcon>
            <FaMobileAlt />
          </PolicyIcon>
          <PolicyHeading>Olleat App</PolicyHeading>
          <PolicyDescription>
            Learn about our data practices in the Olleat app.
          </PolicyDescription>
          <PolicyButton to="/privacy-policy/olleat">
            View Policy
          </PolicyButton>
        </PolicyCard>
      </PolicyGrid>
    </PolicyWrapper>
  </PolicyContainer>
);

export default PrivacyPolicy;
