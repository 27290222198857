// src/pages/OlleatTerms.tsx

import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

// Styled Components
const OlleatTermsContainer = styled.div`
  font-family: 'Open Sans', sans-serif;
  color: #343a40;
  padding: 4rem 2rem;
  background-color: #ffffff;
  max-width: 800px;
  margin: 0 auto;
`;

const TermsTitle = styled.h1`
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const TermsContent = styled.div`
  font-size: 1rem;
  color: #6c757d;
  line-height: 1.6;

  p {
    margin-bottom: 1rem;
  }

  h2 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    color: #343a40;
  }

  h3 {
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
    color: #343a40;
  }

  ul {
    margin-bottom: 1rem;
    padding-left: 1.5rem;
  }

  li {
    margin-bottom: 0.5rem;
  }

  strong {
    font-weight: bold;
  }

  a {
    color: #007bff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const ButtonContainer = styled.div`
  padding-top: 2rem;
  text-align: center;
`;

const CloseButton = styled.button`
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

// OlleatTerms Component
const OlleatTerms: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Olleat App Terms of Service - Family Forge Apps</title>
        <meta name="description" content="Terms of Service for using the Olleat application." />
      </Helmet>
      <OlleatTermsContainer>
        <TermsTitle>Olleat App Terms of Service</TermsTitle>
        <TermsContent>
          {/* Table of Contents */}
          <h2>Table of Contents</h2>
          <ul>
            <li>1. Definitions</li>
            <li>2. Eligibility</li>
            <li>3. Account Registration and Security</li>
            <li>4. User-Generated Content</li>
            <li>5. Intellectual Property Rights</li>
            <li>6. Acceptable Use</li>
            <li>7. Content Moderation</li>
            <li>8. Account Termination or Suspension</li>
            <li>9. Disclaimers</li>
            <li>10. Limitation of Liability</li>
            <li>11. Privacy</li>
            <li>12. Advertisements and Monetization</li>
            <li>13. Support and Contact Information</li>
            <li>14. Governing Law and Dispute Resolution</li>
            <li>15. Changes to Terms</li>
            <li>16. Indemnification</li>
            <li>17. No Third-Party Beneficiaries</li>
            <li>18. Assignment</li>
            <li>19. Severability</li>
            <li>20. Survival</li>
            <li>21. Accessibility</li>
            <li>22. Entire Agreement</li>
          </ul>

          {/* Section 1: Definitions */}
          <h2>1. Definitions</h2>
          <p>
            - <strong>"App"</strong>: The Olleat mobile application and any associated services.
            <br />
            - <strong>"User Content"</strong>: Any content you create, upload, or share on the App, including recipes, images, comments, and other materials.
            <br />
            - <strong>"Services"</strong>: All features, functionalities, and services provided through the App.
          </p>

          {/* Section 2: Eligibility */}
          <h2>2. Eligibility</h2>
          <p>
            You must be at least 16 years old to use Olleat. By creating an account or using the App, you represent and warrant that you meet this age requirement. If you are under the age of majority in your jurisdiction (usually 18 years old), you must have the consent of your parent or legal guardian to use the App.
          </p>

          {/* Section 3: Account Registration and Security */}
          <h2>3. Account Registration and Security</h2>
          <h3>3.1. Registration</h3>
          <p>You can create an account using your email address or through Google OAuth authentication.</p>

          <h3>3.2. Account Security</h3>
          <p>You are responsible for maintaining the confidentiality of your login credentials and for all activities that occur under your account.</p>

          <h3>3.3. Profile Information</h3>
          <p>
            You may upload a profile picture and meal photos using your device's library or camera. Ensure that any images you upload comply with our Acceptable Use guidelines.
          </p>

          {/* Section 4: User-Generated Content */}
          <h2>4. User-Generated Content</h2>
          <h3>4.1. Ownership and License</h3>
          <p>
            You retain all rights and ownership to the content you create and upload on Olleat, including recipes, images, comments, and other materials ("User Content").
          </p>

          <h3>4.2. License to Olleat</h3>
          <p>
            By uploading User Content, you grant Olleat a non-exclusive, worldwide, royalty-free, transferable, and sublicensable license to use, display, reproduce, distribute, modify, adapt, and promote your content within the App and associated services. This license is effective until you delete your User Content or terminate your account, except as necessary for Olleat to comply with legal obligations.
          </p>

          <h3>4.3. Content Deletion</h3>
          <p>
            If you delete your account, your User Content will be marked as private and will no longer be accessible to other users. You can delete your account through the profile tab in the App or by emailing <a href="mailto:customer_support@familyforgeapps.com">customer_support@familyforgeapps.com</a>.
          </p>

          <h3>4.4. Responsibility for Content</h3>
          <p>
            You are solely responsible for your User Content. You represent and warrant that you have all necessary rights to upload the content and that it does not infringe upon any third-party intellectual property or privacy rights.
          </p>

          {/* Section 5: Intellectual Property Rights */}
          <h2>5. Intellectual Property Rights</h2>
          <h3>5.1. Olleat's Content</h3>
          <p>
            All content provided by Olleat, including but not limited to text, graphics, logos, and software, is owned by Olleat and is protected by intellectual property laws.
          </p>

          <h3>5.2. User Content</h3>
          <p>
            While you retain ownership of your User Content, you grant Olleat the rights as specified in Section 4.
          </p>

          {/* Section 6: Acceptable Use */}
          <h2>6. Acceptable Use</h2>
          <p>You agree not to use the App to:</p>
          <ul>
            <li>
              <strong>Illegal Activities:</strong> Engage in any activities that are illegal or violate any laws or regulations.
            </li>
            <li>
              <strong>Hate Speech and Harassment:</strong> Post or transmit content that is hateful, abusive, defamatory, obscene, or otherwise objectionable.
            </li>
            <li>
              <strong>Inappropriate Content:</strong> Upload content that is pornographic, violent, or otherwise inappropriate.
            </li>
            <li>
              <strong>Impersonation:</strong> Misrepresent yourself or impersonate any person or entity.
            </li>
            <li>
              <strong>Malware and Hacking:</strong> Upload viruses, malware, or any other harmful code.
            </li>
          </ul>

          {/* Section 7: Content Moderation */}
          <h2>7. Content Moderation</h2>
          <p>
            While we strive to monitor and moderate content, we cannot guarantee the immediate removal of inappropriate content. Users are encouraged to report any content they find objectionable by using the reporting features within the App.
          </p>

          {/* Section 8: Account Termination or Suspension */}
          <h2>8. Account Termination or Suspension</h2>
          <p>
            We reserve the right to suspend or terminate your account at our sole discretion if we believe you have violated these Terms or our community guidelines. We may notify you of such actions but are not obligated to do so.
          </p>

          {/* Section 9: Disclaimers */}
          <h2>9. Disclaimers</h2>
          <h3>9.1. "As Is" Basis</h3>
          <p>
            The App and all content are provided on an "as is" and "as available" basis without warranties of any kind, either express or implied. Olleat disclaims all warranties, including but not limited to merchantability, fitness for a particular purpose, and non-infringement.
          </p>

          <h3>9.2. Recipe Accuracy and Safety</h3>
          <p>
            Olleat does not guarantee the accuracy, reliability, or safety of any recipes or content provided by users. Use of any recipes is at your own risk. Always verify ingredients and cooking methods for safety.
          </p>

          <h3>9.3. Third-Party Services</h3>
          <p>
            The App may include integrations with third-party services like Google OAuth. We are not responsible for any issues arising from your use of these services.
          </p>

          <h3>9.4. Content Disclaimer</h3>
          <p>
            We are not liable for any offensive or inappropriate content posted by users. We do not endorse any User Content.
          </p>

          {/* Section 10: Limitation of Liability */}
          <h2>10. Limitation of Liability</h2>
          <p>
            To the maximum extent permitted by law, Olleat and its affiliates shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, use, goodwill, or other intangible losses arising from or related to your use of the App.
          </p>

          {/* Section 11: Privacy */}
          <h2>11. Privacy</h2>
          <h3>11.1. Data Collection</h3>
          <p>
            We collect personal data necessary for account creation and functionality, including but not limited to your name, email address, and any content you upload.
          </p>

          <h3>11.2. Use of Personal Information</h3>
          <p>
            Your personal data is used to provide and improve our services, communicate with you, and comply with legal obligations.
          </p>

          <h3>11.3. User Rights</h3>
          <p>
            Depending on your jurisdiction, you may have rights regarding your personal information, such as the right to access, correct, or delete your data. To exercise these rights, contact us at <a href="mailto:customer_support@familyforgeapps.com">customer_support@familyforgeapps.com</a>.
          </p>

          <h3>11.4. Data Retention</h3>
          <p>
            We retain your personal data for as long as necessary to provide our services and comply with legal obligations.
          </p>

          <h3>11.5. Contact Information</h3>
          <p>
            For privacy-related inquiries, please contact us at <a href="mailto:customer_support@familyforgeapps.com">customer_support@familyforgeapps.com</a>.
          </p>

          <h3>11.6. Privacy Policy</h3>
          <p>
            Your personal information is collected and processed in accordance with our Privacy Policy and applicable privacy laws, including the California Consumer Privacy Act (CCPA) and other state privacy regulations.
          </p>

          {/* Section 12: Advertisements and Monetization */}
          <h2>12. Advertisements and Monetization</h2>
          <h3>12.1. Advertisements</h3>
          <p>
            The App may display advertisements. Your interactions with advertisers are solely between you and the advertiser. Olleat is not responsible for any loss or damage incurred from such interactions.
          </p>

          <h3>12.2. Virtual Restaurant Program</h3>
          <p>
            Users who upload recipes may be eligible for compensation through our "Virtual Restaurant Program." Participation is subject to separate terms and conditions.
          </p>

          <h3>12.3. Future Services</h3>
          <p>
            We may offer premium plans, select recipes, or select cookbooks in the future, which will be governed by additional terms.
          </p>

          {/* Section 13: Support and Contact Information */}
          <h2>13. Support and Contact Information</h2>
          <p>
            If you have any questions, concerns, or need assistance, please contact our support team:
          </p>
          <ul>
            <li>Email: <a href="mailto:customer_support@familyforgeapps.com">customer_support@familyforgeapps.com</a></li>
            <li>
              Mailing Address:
              <br />
              Olleat Support Team
              <br />
              3835 Forest Inn Rd.
              <br />
              Palmerton, PA 18071
            </li>
          </ul>

          {/* Section 14: Governing Law and Dispute Resolution */}
          <h2>14. Governing Law and Dispute Resolution</h2>
          <h3>14.1. Governing Law</h3>
          <p>
            These Terms are governed by and construed in accordance with the laws of the State of Delaware, without regard to its conflict of law principles. Notwithstanding the foregoing, you may have additional rights under the mandatory consumer protection laws of your state or country of residence.
          </p>

          <h3>14.2. Arbitration</h3>
          <p>
            Any disputes arising out of or relating to these Terms or the App shall be resolved through binding arbitration administered by the American Arbitration Association under its Consumer Arbitration Rules. The arbitration will be conducted in the county where you reside or another mutually agreed location. Proceedings will be in English.
          </p>

          <h3>14.3. Opt-Out Provision</h3>
          <p>
            You may opt-out of this arbitration agreement by sending a written notice within 30 days of accepting these Terms to:
          </p>
          <p>
            Olleat Legal Department
            <br />
            3835 Forest Inn Rd.
            <br />
            Palmerton, PA 18071
          </p>
          <p>
            Your notice must include your name, address, and a clear statement that you do not wish to resolve disputes through arbitration.
          </p>

          {/* Section 15: Changes to Terms */}
          <h2>15. Changes to Terms</h2>
          <p>
            We reserve the right to modify these Terms at any time. We will provide you with notice of significant changes at least 30 days before they become effective, either through in-app notifications or via email. Your continued use of the App after any changes signifies your acceptance of the new Terms. If you do not agree to the modified Terms, you must stop using the App before the changes take effect.
          </p>

          {/* Section 16: Indemnification */}
          <h2>16. Indemnification</h2>
          <p>
            You agree to indemnify, defend, and hold harmless Olleat, its affiliates, officers, directors, employees, and agents from any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or related to:
          </p>
          <ul>
            <li>Your use or misuse of the App.</li>
            <li>Your violation of these Terms.</li>
            <li>Your violation of any rights of another person or entity.</li>
          </ul>

          {/* Section 17: No Third-Party Beneficiaries */}
          <h2>17. No Third-Party Beneficiaries</h2>
          <p>
            These Terms are intended solely for the benefit of you and Olleat. Nothing in these Terms is intended to confer third-party beneficiary rights upon any other person.
          </p>

          {/* Section 18: Assignment */}
          <h2>18. Assignment</h2>
          <p>
            You may not assign or transfer your rights or obligations under these Terms without Olleat's prior written consent. Olleat may assign its rights and obligations under these Terms without restriction.
          </p>

          {/* Section 19: Severability */}
          <h2>19. Severability</h2>
          <p>
            If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.
          </p>

          {/* Section 20: Survival */}
          <h2>20. Survival</h2>
          <p>
            Provisions that by their nature should survive termination of these Terms shall survive, including but not limited to sections on User-Generated Content, Intellectual Property Rights, Disclaimers, Limitation of Liability, Indemnification, Governing Law and Dispute Resolution, and Privacy.
          </p>

          {/* Section 21: Accessibility */}
          <h2>21. Accessibility</h2>
          <p>
            Olleat is committed to ensuring that our services are accessible to users with disabilities. We strive to comply with the Web Content Accessibility Guidelines (WCAG) 2.1. If you have difficulty accessing any part of the App or these Terms, please contact us at <a href="mailto:customer_support@familyforgeapps.com">customer_support@familyforgeapps.com</a> for assistance.
          </p>

          {/* Section 22: Entire Agreement */}
          <h2>22. Entire Agreement</h2>
          <p>
            These Terms constitute the entire agreement between you and Olleat regarding your use of the App and supersede any prior agreements or understandings.
          </p>
        </TermsContent>
      </OlleatTermsContainer>
    </>
  );
};

export default OlleatTerms;
