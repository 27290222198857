// src/pages/CustomerSupport.tsx

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const SupportContainer = styled.div`
  padding: 4rem 2rem;
  background-color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  color: #343a40;
`;

const SupportWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const SupportTitle = styled.h1`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 3rem;
`;

const SupportText = styled.p`
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 2rem;
  color: #6c757d;
`;

const SupportOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const SupportCard = styled.div`
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const SupportHeading = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const SupportDescription = styled.p`
  font-size: 1rem;
  color: #6c757d;
  margin-bottom: 1.5rem;
`;

const SupportButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ff7f50;
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 50px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #ff6347;
    transform: translateY(-3px);
  }
`;

// Contact Form Styled Components
const ContactFormContainer = styled.div`
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormField = styled.div`
  margin-bottom: 1rem;
`;

const Label = styled.label`
  margin-bottom: 0.5rem;
  display: block;
  font-weight: bold;
`;

const Input = styled.input`
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
`;

const TextArea = styled.textarea`
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  resize: vertical;
`;

const SubmitButton = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: #28a745;
  color: #ffffff;
  border: none;
  border-radius: 50px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #218838;
    transform: translateY(-3px);
  }
`;

// FAQ Section
const FAQSection = styled.div`
  margin-top: 4rem;
`;

const FAQItem = styled.div`
  margin-bottom: 1.5rem;
`;

const Question = styled.h3`
  font-size: 1.2rem;
  color: #343a40;
`;

const Answer = styled.p`
  font-size: 1rem;
  color: #6c757d;
`;

// User Guides Section
const UserGuidesSection = styled.div`
  margin-top: 4rem;
`;

const GuideItem = styled.div`
  margin-bottom: 1rem;
`;

const GuideLink = styled.a`
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

// Troubleshooting Section
const TroubleshootingSection = styled.div`
  margin-top: 4rem;
`;

const TroubleshootingItem = styled.div`
  margin-bottom: 1.5rem;
`;

// Additional Contact Information
const ContactInfo = styled.div`
  margin-top: 4rem;
  text-align: center;
`;

const ContactDetail = styled.p`
  font-size: 1rem;
  color: #6c757d;
`;

// Set Expectations for Response Time
const ResponseTime = styled.p`
  font-size: 1rem;
  color: #28a745;
  text-align: center;
  margin-top: 1rem;
`;

const OlleatCustomerSupport: React.FC = () => {
  return (
    <SupportContainer>
      <SupportWrapper>
        <SupportTitle>Olleat Customer Support</SupportTitle>
        <SupportText>If you have any questions or need help, we are here for you.</SupportText>

        {/* Contact Options */}
        <SupportOptions>
          {/* Email Support */}
          <SupportCard>
            <SupportHeading>Email Support</SupportHeading>
            <SupportDescription>
              Contact us via email for any inquiries or issues. We aim to respond within 24 hours.
            </SupportDescription>
            <SupportButton href="mailto:olleat_customer_support@familyforgeapps.com">
              Get Help via Email
            </SupportButton>
          </SupportCard>
        </SupportOptions>
        {/* FAQs Section */}
        <FAQSection>
          <SupportHeading>Frequently Asked Questions</SupportHeading>
          <FAQItem>
            <Question>How do I create an account?</Question>
            <Answer>You can create an account by downloading the Olleat app and signing up with your email.</Answer>
          </FAQItem>
          <FAQItem>
            <Question>How do I reset my password?</Question>
            <Answer>Click on the "Forgot Password" link on the login page and follow the instructions to reset your password.</Answer>
          </FAQItem>
          <FAQItem>
            <Question>How do I upload a recipe?</Question>
            <Answer>Navigate to the "Add Recipe" section in the app, fill in the required details, and upload your recipe.</Answer>
          </FAQItem>
          <FAQItem>
            <Question>How can I join the Virtual Restaurant Program?</Question>
            <Answer>Complete the required steps in the app's Virtual Restaurant section to enroll in the program.</Answer>
          </FAQItem>
          {/* Add more FAQs as needed */}
        </FAQSection>

        {/* User Guides Section */}
        <UserGuidesSection>
          <SupportHeading>User Guides</SupportHeading>
          <GuideItem>
            <GuideLink href="/guides/getting-started">Getting Started with Olleat</GuideLink>
          </GuideItem>
          <GuideItem>
            <GuideLink href="/guides/uploading-recipes">How to Upload Recipes</GuideLink>
          </GuideItem>
          <GuideItem>
            <GuideLink href="/guides/virtual-restaurant">Virtual Restaurant Program Overview</GuideLink>
          </GuideItem>
          {/* Add more guides as needed */}
        </UserGuidesSection>

        {/* Troubleshooting Section */}
        <TroubleshootingSection>
          <SupportHeading>Troubleshooting</SupportHeading>
          <TroubleshootingItem>
            <Question>App is crashing on startup</Question>
            <Answer>Try restarting your device and ensure you have the latest version of the app installed.</Answer>
          </TroubleshootingItem>
          <TroubleshootingItem>
            <Question>I'm having trouble logging in</Question>
            <Answer>Make sure your email and password are correct. If you've forgotten your password, use the "Forgot Password" feature to reset it.</Answer>
          </TroubleshootingItem>
          <TroubleshootingItem>
            <Question>How do I update the app?</Question>
            <Answer>Visit the App Store (iOS) or Google Play Store (Android) and check for updates in the Olleat app page.</Answer>
          </TroubleshootingItem>
          {/* Add more troubleshooting items as needed */}
        </TroubleshootingSection>

        {/* Additional Contact Information */}
        <ContactInfo>
          <SupportHeading>Other Ways to Contact Us</SupportHeading>
          <ContactDetail>
            <strong>Email:</strong> <a href="mailto:olleat_customer_support@familyforgeapps.com">olleat_customer_support@familyforgeapps.com</a>
          </ContactDetail>
        </ContactInfo>

        {/* Set Expectations for Response Time */}
        <ResponseTime>
          We aim to respond to all inquiries within 24 hours.
        </ResponseTime>
      </SupportWrapper>
    </SupportContainer>
  );
};

export default OlleatCustomerSupport;
