// src/pages/WebsitePrivacyPolicy.tsx

import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

const PrivacyContainer = styled.div`
  font-family: 'Open Sans', sans-serif;
  color: #343a40;
  padding: 4rem 2rem;
  background-color: #ffffff;
  max-width: 900px;
  margin: 0 auto;
`;

const PrivacyTitle = styled.h1`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
  color: #343a40;
`;

const PrivacySection = styled.section`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.8rem;
  color: #343a40;
  margin-bottom: 1rem;
`;

const SectionContent = styled.div`
  font-size: 1rem;
  color: #6c757d;
  line-height: 1.6;

  p {
    margin-bottom: 1rem;
  }

  ul {
    margin-bottom: 1rem;
    padding-left: 1.5rem;
  }

  li {
    margin-bottom: 0.5rem;
  }

  a {
    color: #ff7f50;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const WebsitePrivacyPolicy: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - Family Forge Apps</title>
        <meta
          name="description"
          content="Privacy Policy for Family Forge Apps and Olleat. Learn how we handle your data, ensure your privacy, and comply with Stripe's KYC requirements."
        />
      </Helmet>
      <PrivacyContainer>
        <PrivacyTitle>Privacy Policy</PrivacyTitle>

        {/* Introduction */}
        <PrivacySection>
          <SectionTitle>1. Introduction</SectionTitle>
          <SectionContent>
            <p>
              Welcome to Family Forge Apps. We are committed to protecting your personal information and your right to privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website{' '}
              <a href="https://www.familyforgeapplications.com" target="_blank" rel="noopener noreferrer">
                familyforgeapplications.com
              </a>{' '}
              and use our services, including the Olleat app. Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site or use our services.
            </p>
          </SectionContent>
        </PrivacySection>

        {/* Information We Collect */}
        <PrivacySection>
          <SectionTitle>2. Information We Collect</SectionTitle>
          <SectionContent>
            <p>We collect information that identifies, relates to, describes, or is capable of being associated with you ("Personal Information"). The types of Personal Information we collect include:</p>
            <ul>
              <li>Name, email address, postal address, phone number.</li>
              <li>Payment information processed securely through Stripe.</li>
              <li>Usage data, such as how you interact with our website and services.</li>
              <li>Device information, including IP address, browser type, and operating system.</li>
              <li>Cookies and similar tracking technologies.</li>
            </ul>
          </SectionContent>
        </PrivacySection>

        {/* How We Use Your Information */}
        <PrivacySection>
          <SectionTitle>3. How We Use Your Information</SectionTitle>
          <SectionContent>
            <p>We use the information we collect in the following ways:</p>
            <ul>
              <li>
                <strong>To Provide and Maintain Our Services:</strong> Including processing transactions through Stripe, managing your account, and delivering the Olleat app.
              </li>
              <li>
                <strong>To Improve Our Services:</strong> Analyzing usage data to enhance functionality and user experience.
              </li>
              <li>
                <strong>To Communicate with You:</strong> Sending updates, newsletters, and responding to your inquiries.
              </li>
              <li>
                <strong>For Security:</strong> Protecting against unauthorized access and ensuring the integrity of our services.
              </li>
              <li>
                <strong>Compliance:</strong> Fulfilling legal obligations, including those required by Stripe's KYC policies.
              </li>
            </ul>
          </SectionContent>
        </PrivacySection>

        {/* Sharing Your Information */}
        <PrivacySection>
          <SectionTitle>4. Sharing Your Information</SectionTitle>
          <SectionContent>
            <p>We may share your information in the following circumstances:</p>
            <ul>
              <li>
                <strong>With Service Providers:</strong> Third-party vendors that perform services on our behalf, such as Stripe for payment processing.
              </li>
              <li>
                <strong>Legal Requirements:</strong> If required by law or in response to valid requests by public authorities.
              </li>
              <li>
                <strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets.
              </li>
              <li>
                <strong>With Your Consent:</strong> We may share your information with other parties when you have given us explicit consent.
              </li>
            </ul>
          </SectionContent>
        </PrivacySection>

        {/* Data Security */}
        <PrivacySection>
          <SectionTitle>5. Data Security</SectionTitle>
          <SectionContent>
            <p>
              We implement a variety of security measures to maintain the safety of your Personal Information when you place an order or enter, submit, or access your Personal Information. These security measures include:
            </p>
            <ul>
              <li>Secure Socket Layer (SSL) technology for secure transactions.</li>
              <li>Encryption of sensitive data.</li>
              <li>Regular security assessments and updates.</li>
              <li>Access controls to limit who can access your information.</li>
            </ul>
            <p>
              However, no method of transmission over the internet or electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
            </p>
          </SectionContent>
        </PrivacySection>

        {/* Cookies and Tracking Technologies */}
        <PrivacySection>
          <SectionTitle>6. Cookies and Tracking Technologies</SectionTitle>
          <SectionContent>
            <p>
              We use cookies and similar tracking technologies to track the activity on our website and hold certain information. Cookies are files with a small amount of data that may include an anonymous unique identifier. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
            </p>
            <p>
              Examples of how we use cookies:
            </p>
            <ul>
              <li>To remember your preferences and settings.</li>
              <li>To understand how our website is being used.</li>
              <li>To deliver targeted advertising.</li>
            </ul>
            <p>
              For more information on how we use cookies, please refer to our <a href="/cookie-policy">Cookie Policy</a>.
            </p>
          </SectionContent>
        </PrivacySection>

        {/* Your Rights */}
        <PrivacySection>
          <SectionTitle>7. Your Rights</SectionTitle>
          <SectionContent>
            <p>
              Depending on your location, you may have the following rights regarding your Personal Information:
            </p>
            <ul>
              <li>
                <strong>Access:</strong> Request access to your Personal Information.
              </li>
              <li>
                <strong>Correction:</strong> Request correction of inaccurate or incomplete Personal Information.
              </li>
              <li>
                <strong>Deletion:</strong> Request deletion of your Personal Information.
              </li>
              <li>
                <strong>Restriction of Processing:</strong> Request restriction of processing your Personal Information.
              </li>
              <li>
                <strong>Data Portability:</strong> Request transfer of your Personal Information to another party.
              </li>
              <li>
                <strong>Object:</strong> Object to the processing of your Personal Information.
              </li>
            </ul>
            <p>
              To exercise any of these rights, please contact us at <a href="mailto:privacy@familyforgeapplications.com">privacy@familyforgeapplications.com</a>.
            </p>
          </SectionContent>
        </PrivacySection>

        {/* Children's Privacy */}
        <PrivacySection>
          <SectionTitle>8. Children's Privacy</SectionTitle>
          <SectionContent>
            <p>
              Our services are not directed to individuals under the age of 18. We do not knowingly collect Personal Information from children under 18. If you become aware that a child has provided us with Personal Information, please contact us immediately so that we can take appropriate actions.
            </p>
          </SectionContent>
        </PrivacySection>

        {/* Changes to This Privacy Policy */}
        <PrivacySection>
          <SectionTitle>9. Changes to This Privacy Policy</SectionTitle>
          <SectionContent>
            <p>
              We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
            </p>
            <p>
              Changes to this Privacy Policy are effective when they are posted on this page.
            </p>
          </SectionContent>
        </PrivacySection>

        {/* Contact Us */}
        <PrivacySection>
          <SectionTitle>10. Contact Us</SectionTitle>
          <SectionContent>
            <p>
              If you have any questions about this Privacy Policy, please contact us:
            </p>
            <ul>
              <li>By email: <a href="mailto:privacy@familyforgeapplications.com">privacy@familyforgeapplications.com</a></li>
              <li>By visiting this page on our website: <a href="https://www.familyforgeapplications.com/contact-us" target="_blank" rel="noopener noreferrer">Contact Us</a></li>
            </ul>
          </SectionContent>
        </PrivacySection>
      </PrivacyContainer>
    </>
  );
};

export default WebsitePrivacyPolicy;
