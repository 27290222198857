// src/components/Footer.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: #f8f9fa;
  padding: 1rem 2rem;
  text-align: center;
  margin-top: 2rem;
`;

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <p>&copy; {new Date().getFullYear()} Family Forge Apps. All rights reserved.</p>
      <div>
        <Link to="/customer-support">Olleat Customer Support</Link> |{' '}
        <Link to="/privacy-policy">Privacy Policy</Link> |{' '}
        <Link to="/terms-of-service">Terms of Service</Link>
      </div>
    </FooterContainer>
  );
};

export default Footer;
