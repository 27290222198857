// src/components/Hero.tsx
import React from 'react';
import styled from 'styled-components';
import familyCooking from '../assets/images/hero.png'; // Ensure the path is correct
import { useNavigate } from 'react-router-dom';

const HeroSection = styled.section<{ backgroundImage: string }>`
  position: relative;
  background-image: 
    linear-gradient(
      rgba(0, 0, 0, 0.4), 
      rgba(0, 0, 0, 0.4)
    ),
    url(${props => props.backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 60vh; /* Reduced height from 100vh to 60vh */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff; /* White text for contrast */
  text-align: center;
  padding: 0 20px;

  @media (max-width: 768px) {
    height: 50vh;
  }

  @media (max-width: 480px) {
    height: 40vh;
  }
`;

const ContentWrapper = styled.div`
  position: relative; /* To place content above the overlay */
  z-index: 1;
  max-width: 800px;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  animation: fadeInDown 1s ease-in-out;

  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.8rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  animation: fadeInUp 1s ease-in-out 0.5s forwards;
  opacity: 0;

  @keyframes fadeInUp {
    to {
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

const CTAButton = styled.a`
  display: inline-block;
  background-color: #ff7f50; /* Coral color for the button */
  color: #ffffff;
  padding: 12px 25px;
  font-size: 1rem;
  border-radius: 50px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  opacity: 0;
  animation: fadeIn 1s ease-in-out 1s forwards;

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }

  &:hover {
    background-color: #ff6347; /* Darker coral on hover */
    transform: translateY(-3px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    padding: 8px 18px;
    font-size: 0.8rem;
  }
`;

const ScrollIndicator = styled.div`
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  animation: bounce 2s infinite;
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: translateX(-50%) translateY(0);}
    40% {transform: translateX(-50%) translateY(-10px);}
    60% {transform: translateX(-50%) translateY(-5px);}
  }
`;


const Hero: React.FC = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("about-olleat")
  }
  return (
    <HeroSection backgroundImage={familyCooking}>
      <ContentWrapper>
        <Title>Olleat is Coming December 2024!</Title>
        <Subtitle>
          Organize, Share, and Earn with Your Recipes. Make meal planning and shopping easy. Share your passion and earn real money—all for FREE!
        </Subtitle>
        <CTAButton onClick={handleClick}>Discover Olleat</CTAButton>
      </ContentWrapper>
    </HeroSection>
  );
};

export default Hero;
