// src/pages/ContactUs.tsx

import React from 'react';
import styled from 'styled-components';
import { FaEnvelope } from 'react-icons/fa';

// Styled Components

const ContactContainer = styled.div`
  font-family: 'Open Sans', sans-serif;
  color: #343a40;
`;

const ContactSection = styled.section`
  padding: 4rem 2rem;
  background-color: #ffffff;
`;

const ContactWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
`;

const ContactTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #343a40;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

const InfoIcon = styled.div`
  font-size: 1.5rem;
  color: #ff7f50;
  margin-right: 1rem;
`;

const InfoText = styled.p`
  font-size: 1.2rem;
  color: #6c757d;
`;


// Functional Component

const ContactUs: React.FC = () => {
  return (
    <ContactContainer>
      <ContactSection>
        <ContactWrapper>
          <ContactTitle>Contact Us</ContactTitle>

          {/* Contact Email */}
          <InfoItem>
            <InfoIcon>
              <FaEnvelope />
            </InfoIcon>
            <InfoText>
              <a href="mailto:customer_support@familyforgeapps.com" style={{ color: '#6c757d', textDecoration: 'none' }}>
                customer_support@familyforgeapps.com
              </a>
            </InfoText>
          </InfoItem>


          {/* Optional: Add Social Media Links
          <SocialMedia>
            <SocialIcon href="https://facebook.com" target="_blank" aria-label="Facebook">
              <FaFacebookF />
            </SocialIcon>
            <SocialIcon href="https://twitter.com" target="_blank" aria-label="Twitter">
              <FaTwitter />
            </SocialIcon>
            <SocialIcon href="https://instagram.com" target="_blank" aria-label="Instagram">
              <FaInstagram />
            </SocialIcon>
          </SocialMedia> */}
        </ContactWrapper>
      </ContactSection>
    </ContactContainer>
  );
};

export default ContactUs;
