// src/pages/TermsOfService.tsx

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaGlobe, FaUtensils, FaDollarSign } from 'react-icons/fa'; // Updated Icons

const TermsContainer = styled.div`
  font-family: 'Open Sans', sans-serif;
  color: #343a40;
  padding: 4rem 2rem;
  background-color: #ffffff;
`;

const TermsWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const TermsTitle = styled.h1`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 3rem;
  color: #343a40;
`;

const TermsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const TermsCard = styled.div`
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const TermsIcon = styled.div`
  font-size: 2rem;
  color: #ff7f50;
  margin-bottom: 1rem;
`;

const TermsHeading = styled.h2`
  font-size: 1.5rem;
  color: #343a40;
  margin-bottom: 1rem;
`;

const TermsDescription = styled.p`
  font-size: 1rem;
  color: #6c757d;
  margin-bottom: 1.5rem;
`;

const TermsButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ff7f50;
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 50px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
  
  &:hover {
    background-color: #ff6347;
    transform: translateY(-3px);
  }
  
  svg {
    margin-right: 0.5rem;
  }
`;

const TermsOfService: React.FC = () => {
  return (
    <TermsContainer>
      <TermsWrapper>
        <TermsTitle>Terms of Service</TermsTitle>
        <TermsGrid>
          {/* Family Forge Apps Website Terms */}
          <TermsCard>
            <TermsIcon>
              <FaGlobe />
            </TermsIcon>
            <TermsHeading>Family Forge Apps Website Terms</TermsHeading>
            <TermsDescription>
              Terms and conditions for using the Family Forge Apps website. Please read carefully to ensure compliance.
            </TermsDescription>
            <TermsButton to="/terms-of-service/website">
              View Terms
            </TermsButton>
          </TermsCard>

          {/* Olleat App Terms */}
          <TermsCard>
            <TermsIcon>
              <FaUtensils />
            </TermsIcon>
            <TermsHeading>Olleat App Terms</TermsHeading>
            <TermsDescription>
              Terms and conditions for using the Olleat application. Mandatory agreement for all users.
            </TermsDescription>
            <TermsButton to="/terms-of-service/olleat">
              View Terms
            </TermsButton>
          </TermsCard>

          {/* Olleat Virtual Restaurant Terms */}
          <TermsCard>
            <TermsIcon>
              <FaDollarSign />
            </TermsIcon>
            <TermsHeading>Olleat Virtual Restaurant Terms</TermsHeading>
            <TermsDescription>
              Terms and conditions for participating in the Olleat Virtual Restaurant and earning royalties.
            </TermsDescription>
            <TermsButton to="/terms-of-service/virtual-restaurant">
              View Terms
            </TermsButton>
          </TermsCard>
        </TermsGrid>
      </TermsWrapper>
    </TermsContainer>
  );
};

export default TermsOfService;
