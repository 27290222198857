// src/pages/PrivacyPolicyPage.tsx

import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

const PrivacyContainer = styled.div`
  font-family: 'Open Sans', sans-serif;
  color: #343a40;
  padding: 4rem 2rem;
  background-color: #ffffff;
  max-width: 900px;
  margin: 0 auto;
`;

const PrivacyTitle = styled.h1`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
  color: #343a40;
`;

const PrivacySection = styled.section`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.8rem;
  color: #343a40;
  margin-bottom: 1rem;
`;

const SubSectionTitle = styled.h3`
  font-size: 1.5rem;
  color: #343a40;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
`;

const SectionContent = styled.div`
  font-size: 1rem;
  color: #6c757d;
  line-height: 1.6;

  p {
    margin-bottom: 1rem;
  }

  ul,
  ol {
    margin-bottom: 1rem;
    padding-left: 1.5rem;
  }

  li {
    margin-bottom: 0.5rem;
  }

  a {
    color: #007bff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }
`;

const EffectiveDate = styled.p`
  margin-top: 20px;
  text-align: center;
  font-style: italic;
`;

const PrivacyPolicyPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - Olleat</title>
        <meta
          name="description"
          content="Privacy Policy for Olleat, operated by Family Forge Apps. Learn how we handle your data and ensure your privacy."
        />
      </Helmet>
      <PrivacyContainer>
        <PrivacyTitle>Privacy Policy - Olleat</PrivacyTitle>

        {/* Table of Contents */}
        <PrivacySection>
          <SectionContent>
            <strong>Table of Contents</strong>
            <ol>
              <li>Introduction</li>
              <li>Information We Collect</li>
              <li>How We Use Your Information</li>
              <li>Sharing Your Information</li>
              <li>Third-Party Advertising and Analytics</li>
              <li>Your Choices and Rights</li>
              <li>Security Measures</li>
              <li>Data Retention</li>
              <li>Children's Privacy</li>
              <li>International Data Transfers</li>
              <li>Third-Party Links and Services</li>
              <li>Changes to This Privacy Policy</li>
              <li>Contact Information</li>
              <li>US State Privacy Rights</li>
              <li>European Privacy Rights (GDPR)</li>
            </ol>
          </SectionContent>
        </PrivacySection>

        {/* Section 1: Introduction */}
        <PrivacySection>
          <SectionTitle>1. Introduction</SectionTitle>
          <SectionContent>
            <p>
              Welcome to Olleat, operated by Family Forge Apps ("we," "us," or "our"). We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our mobile application ("App").
            </p>
            <p>
              By using the App, you agree to the collection and use of information in accordance with this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not access the App.
            </p>
          </SectionContent>
        </PrivacySection>

        {/* Section 2: Information We Collect */}
        <PrivacySection>
          <SectionTitle>2. Information We Collect</SectionTitle>
          <SectionContent>
            <SubSectionTitle>2.1. Personal Information</SubSectionTitle>
            <p><strong>Account Registration:</strong> When you create an account, we collect your email address and date of birth to verify eligibility.</p>
            <p><strong>Profile Information:</strong> You may choose to provide additional information such as a profile picture and meal photos.</p>
            <p><strong>User-Generated Content:</strong> Any recipes, comments, or other materials you upload to the App.</p>

            <SubSectionTitle>2.2. Automatically Collected Information</SubSectionTitle>
            <p><strong>Usage Data:</strong> We collect information on how you interact with the App, such as features used and content viewed.</p>
            <p><strong>Device Information:</strong> Information about your device, including device type, operating system, and unique device identifiers.</p>
            <p><strong>Log Data:</strong> Your IP address, access times, and pages viewed.</p>

            <SubSectionTitle>2.3. Cookies and Similar Technologies</SubSectionTitle>
            <p>We may use cookies or similar technologies to collect information about your interactions with the App for analytics and personalization purposes.</p>

            <SubSectionTitle>2.4. Information Collected by Third Parties</SubSectionTitle>
            <p>
              Our App uses third-party services such as Google AdMob, which may collect information used to identify you. This includes advertising identifiers (such as Apple's Identifier for Advertisers, IDFA) and other device information.
            </p>
          </SectionContent>
        </PrivacySection>

        {/* Section 3: How We Use Your Information */}
        <PrivacySection>
          <SectionTitle>3. How We Use Your Information</SectionTitle>
          <SectionContent>
            <SubSectionTitle>3.1. Providing and Improving Services</SubSectionTitle>
            <ul>
              <li>To operate and maintain the App.</li>
              <li>To personalize your experience and deliver content relevant to your interests.</li>
              <li>To monitor and analyze usage and trends to improve the App's functionality.</li>
            </ul>

            <SubSectionTitle>3.2. Communication</SubSectionTitle>
            <ul>
              <li>To send you updates, notifications, and support messages.</li>
              <li>To respond to your comments, questions, and customer service requests.</li>
            </ul>

            <SubSectionTitle>3.3. Advertising</SubSectionTitle>
            <ul>
              <li>To serve personalized advertisements through third-party services like Google AdMob.</li>
              <li>To measure the effectiveness of advertising campaigns.</li>
            </ul>

            <SubSectionTitle>3.4. Legal Compliance</SubSectionTitle>
            <ul>
              <li>To comply with legal obligations, resolve disputes, and enforce our agreements.</li>
            </ul>
          </SectionContent>
        </PrivacySection>

        {/* Section 4: Sharing Your Information */}
        <PrivacySection>
          <SectionTitle>4. Sharing Your Information</SectionTitle>
          <SectionContent>
            <SubSectionTitle>4.1. With Service Providers</SubSectionTitle>
            <p>
              We may share your information with third-party service providers who perform services on our behalf, such as hosting, data analysis, customer service, and advertising. These providers are contractually obligated to protect your information and use it only for the services provided.
            </p>

            <SubSectionTitle>4.2. For Advertising and Analytics</SubSectionTitle>
            <p>
              We use third-party advertising and analytics services like Google AdMob to serve advertisements and analyze App usage. These third parties may collect information sent by your device, such as advertising identifiers, IP address, and data about your usage of the App.
            </p>

            <SubSectionTitle>4.3. For Legal Reasons</SubSectionTitle>
            <p>
              We may disclose your information if required to do so by law or in response to valid requests by public authorities.
            </p>

            <SubSectionTitle>4.4. Business Transfers</SubSectionTitle>
            <p>
              In the event of a merger, acquisition, or asset sale, your personal information may be transferred. We will provide notice before your personal information is transferred and becomes subject to a different Privacy Policy.
            </p>
          </SectionContent>
        </PrivacySection>

        {/* Section 5: Third-Party Advertising and Analytics */}
        <PrivacySection>
          <SectionTitle>5. Third-Party Advertising and Analytics</SectionTitle>
          <SectionContent>
            <p>
              We use third-party services such as Google AdMob to serve personalized advertisements within the App. These services may collect information about your device and usage patterns, including your IP address, device identifiers, and information about your interactions with advertisements.
            </p>

            <SubSectionTitle>5.1. Advertising Identifiers</SubSectionTitle>
            <p>
              We and our third-party partners may use advertising identifiers (such as Apple's IDFA) to deliver personalized ads based on your interests. These identifiers are used to recognize your device and provide relevant advertising content.
            </p>

            <SubSectionTitle>5.2. User Consent and App Tracking Transparency</SubSectionTitle>
            <p>
              In compliance with Apple's App Tracking Transparency framework, we request your permission to track your activity across other companies' apps and websites. If you grant permission, we will use your information to provide personalized ads. If you do not grant permission, you will still see ads, but they may be less relevant to you.
            </p>

            <SubSectionTitle>5.3. Managing Your Preferences</SubSectionTitle>
            <p>
              You can manage your advertising preferences by adjusting your device settings or within the app:
            </p>
            <ul>
              <li><strong>In-App Settings:</strong> Access the app settings to opt-out of personalized advertising at any time.</li>
              <li><strong>On iOS devices:</strong> Go to <em>Settings &gt; Privacy &gt; Tracking</em> to control which apps are allowed to track your activity.</li>
              <li><strong>On Android devices:</strong> Navigate to <em>Settings &gt; Google &gt; Ads</em> to reset your advertising ID or opt-out of personalized ads.</li>
            </ul>

            <SubSectionTitle>5.4. Consent Management</SubSectionTitle>
            <p>
              We comply with applicable data protection laws by requesting consent for data processing activities when required. This includes:
            </p>
            <ul>
              <li><strong>App Tracking Transparency (ATT) for iOS Users:</strong> We request your permission to track your activity across other companies' apps and websites.</li>
              <li><strong>GDPR Consent for EEA Users:</strong> We present a consent form to EEA users to obtain permission for processing personal data and serving personalized ads.</li>
              <li><strong>Compliance with US State Privacy Laws:</strong> We provide users in certain US states with options to opt-out of the sale of personal information and to exercise their privacy rights.</li>
            </ul>
          </SectionContent>
        </PrivacySection>

        {/* Section 6: Your Choices and Rights */}
        <PrivacySection>
          <SectionTitle>6. Your Choices and Rights</SectionTitle>
          <SectionContent>
            <SubSectionTitle>6.1. Access and Update</SubSectionTitle>
            <p>
              You can access and update your personal information within the App settings.
            </p>

            <SubSectionTitle>6.2. Account Deletion</SubSectionTitle>
            <p>
              You may delete your account at any time through the profile tab or by contacting us at <a href="mailto:customer_service@familyforgeapps.com">customer_service@familyforgeapps.com</a>. Upon deletion, your User Content will be marked as private and will no longer be accessible to other users.
            </p>

            <SubSectionTitle>6.3. Managing Your Advertising Preferences</SubSectionTitle>
            <p>
              You can manage your advertising preferences in the following ways:
            </p>
            <ul>
              <li><strong>In-App Settings:</strong> Access the app settings to opt-out of personalized advertising at any time.</li>
              <li><strong>Device Settings:</strong>
                <ul>
                  <li>On iOS devices: Go to <em>Settings &gt; Privacy &gt; Tracking</em> to control which apps are allowed to track your activity.</li>
                  <li>On Android devices: Navigate to <em>Settings &gt; Google &gt; Ads</em> to reset your advertising ID or opt-out of personalized ads.</li>
                </ul>
              </li>
            </ul>

            <SubSectionTitle>6.4. Privacy Rights</SubSectionTitle>
            <p>
              Depending on your jurisdiction, you may have additional rights regarding your personal information, such as the right to access, correct, delete, or restrict processing of your data. To exercise these rights, please contact us at <a href="mailto:privacy_olleat@familyforgeapps.com">privacy_olleat@familyforgeapps.com</a>.
            </p>
          </SectionContent>
        </PrivacySection>

        {/* Section 7: Security Measures */}
        <PrivacySection>
          <SectionTitle>7. Security Measures</SectionTitle>
          <SectionContent>
            <p>
              We implement reasonable security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. These measures include encryption, secure servers, and regular security assessments. However, no method of transmission over the internet or electronic storage is 100% secure.
            </p>
          </SectionContent>
        </PrivacySection>

        {/* Section 8: Data Retention */}
        <PrivacySection>
          <SectionTitle>8. Data Retention</SectionTitle>
          <SectionContent>
            <p>
              We retain your personal information for as long as necessary to provide our services, comply with legal obligations, resolve disputes, and enforce our agreements. The criteria used to determine our retention periods include the length of our relationship with you and legal requirements.
            </p>
          </SectionContent>
        </PrivacySection>

        {/* Section 9: Children's Privacy */}
        <PrivacySection>
          <SectionTitle>9. Children's Privacy</SectionTitle>
          <SectionContent>
            <p>
              Our App is not intended for children under the age of 16. We do not knowingly collect personal information from children under 16. If we become aware that we have collected personal information from a child under 16, we will take steps to delete such information promptly. Parents or guardians who believe that their child has provided us with personal information should contact us at <a href="mailto:privacy_olleat@familyforgeapps.com">privacy_olleat@familyforgeapps.com</a>.
            </p>
          </SectionContent>
        </PrivacySection>

        {/* Section 10: International Data Transfers */}
        <PrivacySection>
          <SectionTitle>10. International Data Transfers</SectionTitle>
          <SectionContent>
            <p>
              Your information may be transferred to and maintained on servers located outside of your state, province, country, or other governmental jurisdiction where data protection laws may differ. We take steps to ensure that your data is treated securely and in accordance with this Privacy Policy.
            </p>
          </SectionContent>
        </PrivacySection>

        {/* Section 11: Third-Party Links and Services */}
        <PrivacySection>
          <SectionTitle>11. Third-Party Links and Services</SectionTitle>
          <SectionContent>
            <p>
              The App may contain links to third-party websites or services that are not owned or controlled by us. We have no control over and assume no responsibility for the privacy practices of these third parties. We encourage you to review the privacy policies of any third-party sites or services before providing them with your personal information.
            </p>
          </SectionContent>
        </PrivacySection>

        {/* Section 12: Changes to This Privacy Policy */}
        <PrivacySection>
          <SectionTitle>12. Changes to This Privacy Policy</SectionTitle>
          <SectionContent>
            <p>
              We may update our Privacy Policy from time to time. We will notify you of any significant changes by posting the new Privacy Policy within the App and updating the "Effective Date" at the end of this policy. Your continued use of the App after any changes signifies your acceptance of the updated policy.
            </p>
          </SectionContent>
        </PrivacySection>

        {/* Section 13: Contact Information */}
        <PrivacySection>
          <SectionTitle>13. Contact Information</SectionTitle>
          <SectionContent>
            <p>
              If you have any questions or concerns about this Privacy Policy or our data practices, please contact us:
            </p>
            <p>
              Email: <a href="mailto:privacy_olleat@familyforgeapps.com">privacy_olleat@familyforgeapps.com</a>
            </p>
            <p>
              Mailing Address:
              <br />
              Olleat Privacy Team
              <br />
              3835 Forest Inn Rd.
              <br />
              Palmerton, PA 18071
            </p>
          </SectionContent>
        </PrivacySection>

        {/* Section 14: US State Privacy Rights */}
        <PrivacySection>
          <SectionTitle>14. US State Privacy Rights</SectionTitle>
          <SectionContent>
            <p>
              Certain US states have enacted privacy laws that grant residents specific rights regarding their personal information. This includes, but is not limited to, residents of California, Colorado, Connecticut, Delaware, Iowa, Montana, Nebraska, New Hampshire, New Jersey, Oregon, Texas, Utah, and Virginia.
            </p>

            <SubSectionTitle>14.1. Right to Know</SubSectionTitle>
            <p>
              You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past 12 months.
            </p>

            <SubSectionTitle>14.2. Right to Delete</SubSectionTitle>
            <p>
              You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions.
            </p>

            <SubSectionTitle>14.3. Right to Opt-Out of Sale</SubSectionTitle>
            <p>
              We do not sell personal information. However, the third-party services we use may involve data sharing that could be considered a "sale" under certain state laws. You have the right to opt-out of the sale of your personal information.
            </p>

            <SubSectionTitle>14.4. Right to Non-Discrimination</SubSectionTitle>
            <p>
              We will not discriminate against you for exercising any of your privacy rights.
            </p>

            <SubSectionTitle>Exercising Your Rights</SubSectionTitle>
            <p>
              To exercise the rights described above, please submit a verifiable consumer request to us by contacting:
            </p>
            <p>
              Email: <a href="mailto:privacy_olleat@familyforgeapps.com">privacy_olleat@familyforgeapps.com</a>
            </p>
          </SectionContent>
        </PrivacySection>

        {/* Section 15: European Privacy Rights (GDPR) */}
        <PrivacySection>
          <SectionTitle>15. European Privacy Rights (GDPR)</SectionTitle>
          <SectionContent>
            <p>
              If you are a resident of the European Economic Area (EEA), you have the following data protection rights under the General Data Protection Regulation (GDPR):
            </p>

            <SubSectionTitle>15.1. Right of Access</SubSectionTitle>
            <p>
              You can request access to your personal data and obtain a copy.
            </p>

            <SubSectionTitle>15.2. Right to Rectification</SubSectionTitle>
            <p>
              You can request correction of inaccurate or incomplete personal data.
            </p>

            <SubSectionTitle>15.3. Right to Erasure</SubSectionTitle>
            <p>
              You can request deletion of your personal data under certain circumstances.
            </p>

            <SubSectionTitle>15.4. Right to Restrict Processing</SubSectionTitle>
            <p>
              You can request that we restrict the processing of your personal data.
            </p>

            <SubSectionTitle>15.5. Right to Data Portability</SubSectionTitle>
            <p>
              You can request a copy of your personal data in a structured, commonly used, and machine-readable format.
            </p>

            <SubSectionTitle>15.6. Right to Object</SubSectionTitle>
            <p>
              You can object to the processing of your personal data based on our legitimate interests.
            </p>

            <SubSectionTitle>15.7. Right to Withdraw Consent</SubSectionTitle>
            <p>
              If we are processing your personal data based on your consent, you have the right to withdraw consent at any time.
            </p>

            <SubSectionTitle>Exercising Your Rights</SubSectionTitle>
            <p>
              To exercise these rights, please contact us at:
            </p>
            <p>
              Email: <a href="mailto:privacy_olleat@familyforgeapps.com">privacy_olleat@familyforgeapps.com</a>
            </p>
          </SectionContent>
        </PrivacySection>

        {/* Effective Date */}
        <EffectiveDate>
          <strong>Effective Date:</strong> 10/22/2024
        </EffectiveDate>
      </PrivacyContainer>
    </>
  );
};

export default PrivacyPolicyPage;
