// src/pages/AboutUs.tsx

import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import teamMember2 from '../assets/images/lindsay.jpg'; // Placeholder image paths
import teamMember1 from '../assets/images/kyle.jpg';
import olleatScreenshot from '../assets/images/screenshot1.png';
import aboutVerticalImage from '../assets/images/about_us_full.jpg'; // New Image

const AboutUsContainer = styled.div`
  width: 100%;
  overflow-x: hidden;
`;

/* Section Styles */
const Section = styled.section`
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

/* Introduction Section */
const IntroSection = styled(Section)`
  background-color: #ffffff;
`;

const IntroTitle = styled.h1`
  font-size: 2.5rem;
  color: #343a40;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const IntroText = styled.p`
  font-size: 1.2rem;
  color: #6c757d;
  max-width: 800px;
`;

/* Mission and Vision Section */
const MissionSection = styled(Section)`
  background-color: #f8f9fa;
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  color: #343a40;
  margin-bottom: 1rem;
`;

const SectionContent = styled.p`
  font-size: 1rem;
  color: #6c757d;
  max-width: 800px;
`;

/* Company History Section */
const HistorySection = styled(Section)`
  background-color: #ffffff;
`;

const HistoryText = styled.p`
  font-size: 1rem;
  color: #6c757d;
  max-width: 800px;
`;

/* Values and Culture Section */
const ValuesSection = styled(Section)`
  background-color: #f8f9fa;
`;

const ValuesList = styled.ul`
  list-style: none;
  padding: 0;
  max-width: 800px;
`;

const ValueItem = styled.li`
  font-size: 1rem;
  color: #6c757d;
  margin-bottom: 0.5rem;
  position: relative;
  padding-left: 1.5rem;

  &::before {
    content: '✔️';
    position: absolute;
    left: 0;
    top: 0;
    color: #ff7f50;
  }
`;

/* Team Section */
const TeamSection = styled(Section)`
  background-color: #ffffff;
`;

const TeamGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
`;

const TeamMemberCard = styled(motion.div)`
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 1.5rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 16px rgba(0, 0, 0, 0.2);
  }
`;

const TeamMemberImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
`;

const TeamMemberName = styled.h3`
  font-size: 1.2rem;
  color: #343a40;
  margin-bottom: 0.5rem;
`;

const TeamMemberRole = styled.h4`
  font-size: 1rem;
  color: #6c757d;
  margin-bottom: 0.5rem;
`;

const TeamMemberBio = styled.p`
  font-size: 0.9rem;
  color: #6c757d;
`;

/* Achievements Section */
const AchievementsSection = styled(Section)`
  background-color: #f8f9fa;
`;

const Timeline = styled.div`
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  
  &::after {
    content: '';
    position: absolute;
    width: 4px;
    background-color: #ff7f50;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -2px;
  }
`;

const TimelineItem = styled(motion.div) <{ position: 'left' | 'right' }>`
  padding: 1rem 2rem;
  position: relative;
  width: 50%;
  display: flex;
  justify-content: ${({ position }) => (position === 'left' ? 'flex-end' : 'flex-start')};
  
  @media (max-width: 768px) {
    width: 100%;
    justify-content: flex-start;
    padding-left: 4rem;
    padding-right: 2rem;
  }

  &::before {
    content: '';
    position: absolute;
    top: 15px;
    ${({ position }) => (position === 'left' ? 'right: -8px;' : 'left: -8px;')}
    width: 16px;
    height: 16px;
    background-color: #ff7f50;
    border-radius: 50%;
    z-index: 1;
  }
`;

const TimelineContent = styled.div`
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 5px;
  position: relative;
  width: 90%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

/* Project Highlight Section */
const ProjectHighlightSection = styled(Section)`
  background-color: #ffffff;
`;

const ProjectHighlightContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1200px;
  gap: 2rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
  object-fit: cover;
`;

const ProjectText = styled.div`
  max-width: 600px;
  text-align: left;
`;

const ProjectTitle = styled.h2`
  font-size: 2rem;
  color: #343a40;
  margin-bottom: 1rem;
`;

const ProjectDescription = styled.p`
  font-size: 1rem;
  color: #6c757d;
`;

/* About Family Forge Apps Section */
const AboutSectionStyled = styled.section`
  padding: 4rem 2rem;
  background-color: #f8f9fa;
`;

const AboutWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
`;

const AboutImage = styled.img`
  flex: 1 1 400px;
  max-width: 100%;
  border-radius: 10px;
`;

const AboutText = styled.div`
  flex: 1 1 400px;
`;

const AboutTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #343a40;
`;

const AboutDescription = styled.p`
  font-size: 1rem;
  color: #6c757d;
`;

/* Mission & Vision Section */
const MissionSectionStyled = styled.section`
  padding: 4rem 2rem;
  background-color: #ffffff;
`;

const MissionWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap-reverse;
  gap: 2rem;
`;

const MissionText = styled.div`
  flex: 1 1 400px;
`;

const MissionImage = styled.img`
  flex: 1 1 400px;
  max-width: 100%;
  border-radius: 10px;
`;

const MissionTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #343a40;
`;

const MissionDescription = styled.p`
  font-size: 1rem;
  color: #6c757d;
`;

/* Animation Variants */
const fadeInUp = {
  hidden: { opacity: 0, y: 30 },
  visible: { opacity: 1, y: 0 },
};

const fadeInLeft = {
  hidden: { opacity: 0, x: -30 },
  visible: { opacity: 1, x: 0 },
};

const fadeInRight = {
  hidden: { opacity: 0, x: 30 },
  visible: { opacity: 1, x: 0 },
};

/* Functional Component */

const AboutUs: React.FC = () => {
  return (
    <AboutUsContainer>
      {/* Introduction Section */}
      <IntroSection>
        <IntroTitle>About Family Forge Apps</IntroTitle>
        <IntroText>
          At Family Forge Apps, we are dedicated to developing innovative, accessible, and user-friendly applications that enhance family life, foster connections, and bring joy to everyday activities. Founded by Lindsay and Kyle Laster in 2024, our passion lies in creating solutions that are both high-quality and user-centric.
        </IntroText>
      </IntroSection>

      {/* Mission and Vision Section */}
      <MissionSectionStyled>
        <MissionWrapper>
          <MissionText>
            <MissionTitle>Our Mission & Vision</MissionTitle>
            <MissionDescription>
              <strong>Mission:</strong> "At Family Forge Apps, our mission is to develop innovative, accessible, and user-friendly applications that enhance family life, foster connections, and bring joy to everyday activities."
            </MissionDescription>
            <MissionDescription>
              <strong>Vision:</strong> "Our long-term vision is to become a leading app development company dedicated to creating tools that strengthen family bonds and simplify family life. With Olleat, we aim to revolutionize the way families engage with culinary activities, making cooking and meal planning a delightful and rewarding experience for everyone."
            </MissionDescription>
          </MissionText>
          <MissionImage src={aboutVerticalImage} alt="About Family Forge Apps" />
        </MissionWrapper>
      </MissionSectionStyled>

      {/* Values and Culture Section */}
      <ValuesSection>
        <SectionTitle>Our Values & Culture</SectionTitle>
        <ValuesList>
          <ValueItem>Family-Centric Innovation: Prioritizing the needs of families in our app development.</ValueItem>
          <ValueItem>Excellence in User Experience: Delivering intuitive and enjoyable user interfaces.</ValueItem>
          <ValueItem>Privacy and Security: Ensuring user data is protected with the highest standards.</ValueItem>
          <ValueItem>Creativity and Detail Orientation: Embracing creativity while maintaining meticulous attention to detail.</ValueItem>
          <ValueItem>Accessibility and Inclusivity: Making our applications accessible to all families, regardless of their background.</ValueItem>
          <ValueItem>Community and Collaboration: Fostering a sense of community among our users and team members.</ValueItem>
        </ValuesList>
        <HistoryText>
          At Family Forge Apps, we cultivate a collaborative and inclusive culture that values creativity, innovation, and continuous learning. Our team is dedicated to working together to develop applications that make a meaningful difference in family life. We encourage open communication, support individual growth, and celebrate our collective achievements as we strive to push the boundaries of what's possible in family-oriented app development.
        </HistoryText>
      </ValuesSection>

      {/* Team Section */}
      <TeamSection>
        <SectionTitle>Meet Our Team</SectionTitle>
        <TeamGrid>
          <TeamMemberCard
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            variants={fadeInUp}
          >
            <TeamMemberImage src={teamMember2} alt="Lindsay Laster" />
            <TeamMemberName>Lindsay Laster</TeamMemberName>
            <TeamMemberRole>Co-Founder & CEO</TeamMemberRole>
            <TeamMemberBio>
              Lindsay is an experienced businesswoman, managing the launch of wildly successful brands like Kik-Fix. As the Co-Founder of Family Forge Apps, Lindsay's vision for marketing and her commitment to excellence drive the company's growth and propel it towards achieving new heights.
            </TeamMemberBio>
          </TeamMemberCard>

          <TeamMemberCard
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            variants={fadeInLeft}
          >
            <TeamMemberImage src={teamMember1} alt="Kyle Laster" />
            <TeamMemberName>Kyle Laster</TeamMemberName>
            <TeamMemberRole>Co-Founder & CTO</TeamMemberRole>
            <TeamMemberBio>
              Kyle is a seasoned engineer with extensive experience in financial technologies and mobile applications. As the Chief Technology Officer at Family Forge Apps, Kyle is dedicated to delivering exceptional user experiences through superior UX/UI design, robust privacy and security measures, and creating applications that are truly worth using.
            </TeamMemberBio>
          </TeamMemberCard>
        </TeamGrid>
      </TeamSection>

      {/* Achievements Section */}
      <AchievementsSection>
        <SectionTitle>Our Achievements</SectionTitle>
        <Timeline>
          <TimelineItem position="left" variants={fadeInLeft} initial="hidden" whileInView="visible" viewport={{ once: true, amount: 0.3 }}>
            <TimelineContent>
              <h3>2024</h3>
              <p>Founded Family Forge Apps with a vision to innovate family-centric applications.</p>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem position="right" variants={fadeInRight} initial="hidden" whileInView="visible" viewport={{ once: true, amount: 0.3 }}>
            <TimelineContent>
              <h3>2025</h3>
              <p>Launched our first application, Olleat, revolutionizing meal planning and recipe sharing.</p>
            </TimelineContent>
          </TimelineItem>
          {/* Add more timeline items as needed */}
        </Timeline>
      </AchievementsSection>

      {/* Project Highlights Section */}
      <ProjectHighlightSection>
        <SectionTitle>Olleat: Our Flagship Project</SectionTitle>
        <ProjectHighlightContent>
          <ProjectImage src={olleatScreenshot} alt="Olleat App Screenshot" />
          <ProjectText>
            <ProjectTitle>Olleat</ProjectTitle>
            <ProjectDescription>
              Olleat is our flagship application designed to revolutionize family culinary experiences. With features that simplify meal planning, recipe management, and community engagement, Olleat aims to make cooking a delightful and rewarding activity for families. Launching in December 2024, Olleat embodies our commitment to creating applications that foster family connections and enhance daily life.
            </ProjectDescription>
          </ProjectText>
        </ProjectHighlightContent>
      </ProjectHighlightSection>
    </AboutUsContainer>
  );
};

export default AboutUs;
