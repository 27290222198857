import React from 'react';
import Hero from '../components/Hero';
import AppShowcase from '../components/AppShowcase';

const HomePage: React.FC = () => {
  return (
    <>
      <main>
        <Hero />
        <AppShowcase />
      </main>
    </>
  );
};

export default HomePage;
