// src/pages/WebsiteTerms.tsx

import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

const WebsiteTermsContainer = styled.div`
  font-family: 'Open Sans', sans-serif;
  color: #343a40;
  padding: 4rem 2rem;
  background-color: #ffffff;
  max-width: 800px;
  margin: 0 auto;
`;

const TermsTitle = styled.h1`
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const TermsContent = styled.div`
  font-size: 1rem;
  color: #6c757d;
  line-height: 1.6;

  p {
    margin-bottom: 1rem;
  }

  h2 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    color: #343a40;
  }

  ul {
    margin-bottom: 1rem;
    padding-left: 1.5rem;
  }

  li {
    margin-bottom: 0.5rem;
  }
`;

const WebsiteTerms: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Website Terms of Service - Family Forge Apps</title>
        <meta name="description" content="Terms of Service for using the Family Forge Apps website." />
      </Helmet>
      <WebsiteTermsContainer>
        <TermsTitle>Family Forge Apps Website Terms of Service</TermsTitle>
        <TermsContent>
          <p>Welcome to Family Forge Apps! Please read these Terms of Service carefully before using our website.</p>

          <h2>1. Acceptance of Terms</h2>
          <p>By accessing and using this website, you accept and agree to be bound by the terms and provision of this agreement.</p>

          <h2>2. Use of the Website</h2>
          <p>Family Forge Apps grants you a limited, non-exclusive, non-transferable license to access and use the website for personal, non-commercial purposes.</p>

          <h2>3. User Responsibilities</h2>
          <ul>
            <li>Maintain the confidentiality of your account information.</li>
            <li>Ensure that all information provided is accurate and up-to-date.</li>
            <li>Use the website in compliance with all applicable laws and regulations.</li>
          </ul>

          <h2>4. Privacy Policy</h2>
          <p>Your use of the website is also governed by our <a href="/privacy-policy">Privacy Policy</a>.</p>

          <h2>5. Intellectual Property</h2>
          <p>All content, trademarks, and data on this website are the property of Family Forge Apps and are protected by applicable intellectual property laws.</p>

          <h2>6. Disclaimer of Warranties</h2>
          <p>The website is provided on an "AS IS" and "AS AVAILABLE" basis. Family Forge Apps disclaims all warranties, whether express or implied.</p>

          <h2>7. Limitation of Liability</h2>
          <p>Family Forge Apps shall not be liable for any damages arising out of or in connection with the use of this website.</p>

          <h2>8. Governing Law</h2>
          <p>These terms shall be governed by and construed in accordance with the laws of [Your State/Country].</p>

          <h2>9. Changes to Terms</h2>
          <p>Family Forge Apps reserves the right to modify these Terms of Service at any time. Changes will be effective immediately upon posting.</p>

          <h2>10. Contact Us</h2>
          <p>If you have any questions about these Terms of Service, please contact us at <a href="mailto:customer_support@familyforgeapps.com">customer_support@familyforgeapps.com</a>.</p>
        </TermsContent>
      </WebsiteTermsContainer>
    </>
  );
};

export default WebsiteTerms;
