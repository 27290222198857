// src/App.tsx

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import AboutOlleat from './pages/AboutOlleat';
import ContactUs from './pages/ContactUs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import WebsitePrivacyPolicy from './pages/WebsitePrivacyPolicy'; // New import
import OlleatPrivacyPolicy from './pages/OlleatPrivacyPolicy';   // New import
import TermsOfService from './pages/TermsOfService';
import WebsiteTerms from './pages/WebsiteTerms';
import OlleatTerms from './pages/OlleatTerms';
import VirtualRestaurantTerms from './pages/VirtualRestaurantTerms';
import GlobalStyles from './styles/GlobalStyles';
import ScrollToTop from './components/ScrollToTop';
import OlleatCustomerSupport from './pages/OlleatCustomerSupport';

const App: React.FC = () => {
  return (
    <Router>
      <ScrollToTop />
      <GlobalStyles />
      <Header />
      <Routes>
        {/* Existing Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/about-olleat" element={<AboutOlleat />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/terms-of-service/website" element={<WebsiteTerms />} />
        <Route path="/terms-of-service/olleat" element={<OlleatTerms />} />
        <Route path="/terms-of-service/virtual-restaurant" element={<VirtualRestaurantTerms />} />

        {/* Updated Privacy Policy Routes */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/privacy-policy/website" element={<WebsitePrivacyPolicy />} />
        <Route path="/privacy-policy/olleat" element={<OlleatPrivacyPolicy />} />
        <Route path="/customer-support" element={<OlleatCustomerSupport />} />

      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
