// src/pages/AboutOlleat.tsx

import React from 'react';
import styled from 'styled-components';
import {
  FaBook,
  FaSearch,
  FaClipboardList,
  FaShareAlt,
  FaUsers,
  FaDollarSign,
  FaShoppingCart,
  FaLock, // Imported FaLock
} from 'react-icons/fa';

// Import images (Ensure these images are available in the specified path)
import heroImage from '../assets/images/hero.png'; // Replace with your actual image
import communityImage from '../assets/images/screenshot1.png'; // Replace with your actual image
import rewardsImage from '../assets/images/screenshot2.png'; // Replace with your actual image
import AppShowcase from '../components/AppShowcase';

// Styled Components

const AboutOlleatContainer = styled.div`
  font-family: 'Open Sans', sans-serif;
  color: #343a40;
`;

// Hero Section

const HeroSection = styled.section<{ backgroundImage: string }>`
  position: relative;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  text-align: center;
  padding: 0 20px;

  /* Overlay */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 768px) {
    height: 50vh;
  }

  @media (max-width: 480px) {
    height: 40vh;
  }
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 1;
  max-width: 800px;
`;

const HeroTitle = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const HeroSubtitle = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const CTAButton = styled.a`
  display: inline-block;
  background-color: #ff7f50;
  color: #ffffff;
  padding: 12px 25px;
  font-size: 1rem;
  border-radius: 50px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    background-color: #ff6347;
    transform: translateY(-3px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
`;

// Key Features Section

const FeaturesSection = styled.section`
  padding: 4rem 2rem;
  background-color: #f8f9fa;
`;

const FeaturesWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
`;

const FeatureCard = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 16px rgba(0, 0, 0, 0.2);
  }
`;

const FeatureIconWrapper = styled.div`
  font-size: 3rem;
  color: #ff7f50;
  margin-bottom: 1rem;
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #343a40;
`;

const FeatureDescription = styled.p`
  font-size: 1rem;
  color: #6c757d;
`;

// Why Choose Olleat Section

const WhyChooseSection = styled.section`
  padding: 4rem 2rem;
`;

const WhyChooseWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
`;

const WhyChooseTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 2rem;
`;

const WhyChooseGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
`;

const WhyChooseCard = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const WhyChooseIcon = styled.div`
  font-size: 2.5rem;
  color: #ff7f50;
  margin-bottom: 1rem;
`;

const WhyChooseText = styled.p`
  font-size: 1rem;
  color: #6c757d;
`;

// Community and Rewards Sections

const CommunitySection = styled.section`
  padding: 4rem 2rem;
  background-color: #f8f9fa;
`;

const CommunityWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const CommunityContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 2rem;
`;

const CommunityImage = styled.img`
  flex: 1 1 400px;
  max-width: 100%;
  border-radius: 10px;
`;

const CommunityText = styled.div`
  flex: 1 1 400px;
`;

const CommunityTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const CommunityDescription = styled.p`
  font-size: 1rem;
  color: #6c757d;
`;

const RewardsSection = styled.section`
  padding: 4rem 2rem;
`;

const RewardsWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const RewardsContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 2rem;
`;

const RewardsImage = styled.img`
  flex: 1 1 400px;
  max-width: 100%;
  border-radius: 10px;
`;

const RewardsText = styled.div`
  flex: 1 1 400px;
`;

const RewardsTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const RewardsDescription = styled.p`
  font-size: 1rem;
  color: #6c757d;
`;

// Final Call to Action Section

const FinalCTASection = styled.section`
  padding: 4rem 2rem;
  text-align: center;
`;

const FinalCTATitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const FinalCTADescription = styled.p`
  font-size: 1rem;
  color: #6c757d;
  margin-bottom: 2rem;
`;

const FinalCTAButton = styled.a`
  display: inline-block;
  background-color: #ff7f50;
  color: #ffffff;
  padding: 12px 25px;
  font-size: 1rem;
  border-radius: 50px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    background-color: #ff6347;
    transform: translateY(-3px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
`;

// Functional Component

const AboutOlleat: React.FC = () => {
  return (
    <AboutOlleatContainer>
      {/* Hero Section */}
      <HeroSection backgroundImage={heroImage}>
        <HeroContent>
          <HeroTitle>
            Olleat: Your Ultimate Family-Oriented Culinary Companion
          </HeroTitle>
          <HeroSubtitle>
            Welcome to Olleat, launching in December 2024! Enhance your culinary
            experiences and bring your family closer through the love of cooking
            and sharing delicious recipes.
          </HeroSubtitle>
          <CTAButton href="#features">Explore Features</CTAButton>
        </HeroContent>
      </HeroSection>

      {/* Key Features Section */}
      <FeaturesSection id="features">
        <FeaturesWrapper>
          <h2 style={{ textAlign: 'center', marginBottom: '2rem' }}>
            Key Features of Olleat
          </h2>
          <FeaturesGrid>
            {/* Feature 1 */}
            <FeatureCard>
              <FeatureIconWrapper>
                <FaBook />
              </FeatureIconWrapper>
              <FeatureTitle>Manage and Edit Recipes with Ease</FeatureTitle>
              <FeatureDescription>
                Organize your personal collection effortlessly with custom
                cookbooks and intuitive editing tools.
              </FeatureDescription>
            </FeatureCard>

            {/* Feature 2 */}
            <FeatureCard>
              <FeatureIconWrapper>
                <FaSearch />
              </FeatureIconWrapper>
              <FeatureTitle>Discover New Recipes</FeatureTitle>
              <FeatureDescription>
                Explore a vast library of global recipes with personalized
                recommendations tailored to your preferences.
              </FeatureDescription>
            </FeatureCard>

            {/* Feature 3 */}
            <FeatureCard>
              <FeatureIconWrapper>
                <FaClipboardList />
              </FeatureIconWrapper>
              <FeatureTitle>Simplify Meal Planning</FeatureTitle>
              <FeatureDescription>
                Plan your weekly meals with our smart meal planner and generate
                instant shopping lists for a hassle-free grocery experience.
              </FeatureDescription>
            </FeatureCard>

            {/* Feature 4 */}
            <FeatureCard>
              <FeatureIconWrapper>
                <FaShareAlt />
              </FeatureIconWrapper>
              <FeatureTitle>Create and Share Cookbooks</FeatureTitle>
              <FeatureDescription>
                Compile your favorite recipes into personalized cookbooks and
                share them with family and friends.
              </FeatureDescription>
            </FeatureCard>

            {/* Feature 5 */}
            <FeatureCard>
              <FeatureIconWrapper>
                <FaUsers />
              </FeatureIconWrapper>
              <FeatureTitle>Engage with a Community of Food Enthusiasts</FeatureTitle>
              <FeatureDescription>
                Share your culinary creations, participate in discussions, and
                connect with fellow food lovers.
              </FeatureDescription>
            </FeatureCard>

            {/* Feature 6 */}
            <FeatureCard>
              <FeatureIconWrapper>
                <FaDollarSign />
              </FeatureIconWrapper>
              <FeatureTitle>Earn Rewards with the Virtual Restaurant Program</FeatureTitle>
              <FeatureDescription>
                Monetize your recipes by launching your own Virtual Restaurant
                and earn rewards based on recipe performance.
              </FeatureDescription>
            </FeatureCard>

            {/* Feature 7 - Instant Shopping Lists */}
            <FeatureCard>
              <FeatureIconWrapper>
                <FaShoppingCart />
              </FeatureIconWrapper>
              <FeatureTitle>Instant Shopping Lists</FeatureTitle>
              <FeatureDescription>
                Generate comprehensive shopping lists with a single tap. Olleat
                automatically compiles all necessary ingredients for your planned
                meals, making grocery shopping faster and more efficient.
              </FeatureDescription>
            </FeatureCard>

            {/* Feature 8 - Keep Your Family Recipes Safe */}
            <FeatureCard>
              <FeatureIconWrapper>
                <FaLock />
              </FeatureIconWrapper>
              <FeatureTitle>Keep Your Family Recipes Safe</FeatureTitle>
              <FeatureDescription>
                Olleat only shares recipes you want us to. If you want to protect
                grandma's secret biscuits and gravy, it's locked behind our state-of-the-art security.
              </FeatureDescription>
            </FeatureCard>
          </FeaturesGrid>
        </FeaturesWrapper>
      </FeaturesSection>

      {/* App Showcase Section */}
      <AppShowcase />

    </AboutOlleatContainer>
  );
};

export default AboutOlleat;
